import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { RouterModule } from '@angular/router';
@NgModule({
	imports: [CommonModule, RouterModule],
	exports: [FooterComponent],
	declarations: [FooterComponent],
	providers: []
})
export class FooterModule {}

import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
	selector: 'app-modal-offline',
	templateUrl: './modal-offline.component.html',
	styleUrls: ['./modal-offline.component.scss']
})
export class ModalOfflineComponent implements OnInit, OnDestroy {
	constructor() {}

	ngOnInit(): void {
		document.body.classList.add('show__scroll');
	}

	ngOnDestroy(): void {
		document.body.classList.remove('show__scroll');
	}
}

<button
	*ngIf="right && (icon || srcImg)"
	[class]="class + color + hoverColor"
	[disabled]="disabled"
	(mouseenter)="onMouseEnter()"
	[type]="type"
	[class.white]="isWhite"
>
	<ng-container *ngIf="!disabled">
		{{ text }}
		<img [src]="'assets/icons/' + srcImg" />
	</ng-container>
	<ng-container *ngIf="disabled">
		<span>Cargando</span>
		<div class="load1 load"></div>
		<div class="load2 load"></div>
		<div class="load3 load"></div>
	</ng-container>
</button>
<button
	*ngIf="left && (icon || srcImg)"
	[class]="class + color + hoverColor"
	[disabled]="disabled"
	(mouseenter)="onMouseEnter()"
	[type]="type"
	[class.white]="isWhite"
>
	<ng-container *ngIf="!disabled">
		<img [src]="'assets/icons/' + srcImg" />
		{{ text }}
	</ng-container>
	<ng-container *ngIf="disabled">
		<span>Cargando</span>
		<div class="load1 load"></div>
		<div class="load2 load"></div>
		<div class="load3 load"></div>
	</ng-container>
</button>
<button
	*ngIf="!right && !left && !icon && !srcImg && !isInitDisabled && link === ''"
	[class]="class + color + hoverColor"
	[disabled]="disabled"
	(mouseenter)="onMouseEnter()"
	[type]="type"
	[class.white]="isWhite"
>
	<ng-container *ngIf="!disabled">
		{{ text }}
	</ng-container>
	<ng-container *ngIf="disabled">
		<span>Cargando</span>
		<div class="load1 load"></div>
		<div class="load2 load"></div>
		<div class="load3 load"></div>
	</ng-container>
</button>

<button
	*ngIf="isInitDisabled"
	[class]="class + color + hoverColor"
	[ngClass]="isRecover ? 'lg-500' : 'lg-300'"
	[class.bgDisabled]="bgDisabled"
	[class.lg]="!bgDisabled"
	(mouseenter)="onMouseEnter()"
	[disabled]="disabled"
	[type]="type"
	[class.white]="isWhite"
>
	<ng-container *ngIf="!disabled">
		{{ text }}
	</ng-container>
	<ng-container *ngIf="disabled">
		<span>Cargando</span>
		<div class="load1 load"></div>
		<div class="load2 load"></div>
		<div class="load3 load"></div>
	</ng-container>
</button>
<ng-container *ngIf="link !== ''">
	<a
		*ngIf="blank"
		[class]="class + color + hoverColor"
		(mouseenter)="onMouseEnter()"
		[href]="[link]"
		[target]="'_blank'"
		target="_blank"
	>
		{{ text }}
	</a>

	<a *ngIf="!blank" [class]="class + color + hoverColor" (mouseenter)="onMouseEnter()" [routerLink]="link">
		{{ text }}
	</a>
</ng-container>

<ng-container *ngIf="getScreenWidth >= 1200; else sidebarResponsive">
	<div class="container__sidebar">
		<div class="sidebar__header">Mi cuenta</div>
		<div class="sidebar__options">
			<ng-container *ngFor="let option of listMenu">
				<div class="option__menu" [class.active__option]="isActivePage(option.url)" (click)="changePage(option.url)">
					<app-menu-icon class="option__icon" [iconName]="option.icon"></app-menu-icon>
					<div class="option__label">
						{{ option.name }}
					</div>
				</div>
			</ng-container>
			<div class="container__logout__button">
				<span (click)="closeSesion()">Cerrar sesión</span>
			</div>
		</div>
	</div>
</ng-container>
<ng-template #sidebarResponsive>
	<div class="container__sidebar">
		<div class="sidebar__header">
			<span>Mi cuenta</span>
			<app-notification-alert class="notification__alert"></app-notification-alert>
		</div>
		<div class="dropdown__options">
			<div class="dropdown__header" (click)="openMenu()">
				<span>{{ getActivePage() }}</span>
				<div class="icon__arrow">
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M12 6L8 10L4 6"
							stroke="#404040"
							stroke-width="1.67"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</div>
			</div>
			<div class="dropdown__items" [class.open__option]="showOptions">
				<ng-container *ngFor="let option of listMenu">
					<div class="menu__item" [class.active__item]="isActivePage(option.url)" (click)="changePage(option.url)">
						<span>{{ option.name }}</span>
					</div>
				</ng-container>
				<div class="menu__item item__logout">
					<span (click)="closeSesion()">Cerrar sesión</span>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<app-alerta
	*ngIf="showAlert"
	[title]="'Error de sesion'"
	[icon]="'warning-2.svg'"
	[isPage]="true"
	[messages]="[messageError]"
	(close)="closeAlert()"
	[closeTime]="true"
></app-alerta>

import { Component, Input } from '@angular/core';
import { Nosotros } from 'src/app/core/interfaces/about.interface';
import { AboutService } from 'src/app/core/services/api/about-api.service';
import { BannerService } from 'src/app/core/services/api/banner-api.service';

@Component({
	selector: 'app-social-networks',
	templateUrl: './social-networks.component.html',
	styleUrls: ['./social-networks.component.scss']
})
export class SocialNetworksComponent {
	@Input() stopSocialNetworks = false;
	@Input() bottomAbsolutePosition = 0;
	dataLinks!: Nosotros;
	constructor(public bannerService: BannerService, public _aboutService: AboutService) {}

	navigateUrl(link: string) {
		window.open(link, '_blank');
	}
}

/* eslint-disable prefer-const */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateViaAuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/store/store.module').then((m) => m.StoreModule)
	},
	{
		path: 'mi-cuenta',
		loadChildren: () => import('./pages/my-account/my-account.module').then((m) => m.MyAccountModule),
		canActivate: [CanActivateViaAuthGuard]
	},
	{
		path: 'noexist',
		loadChildren: () => import('./pages/notexist-page/notexist-page.module').then((m) => m.NotExistPageModule)
	},
	{
		path: 'politicas-de-privacidad',
		loadChildren: () => import('./pages/politics-page/politics-page.module').then((m) => m.PoliticsPageModule)
	},
	{
		path: 'terminos-y-condiciones',
		loadChildren: () => import('./pages/terms-page/terms-page.module').then((m) => m.TermsPageModule)
	},
	{
		path: 'politicas-de-cookies',
		loadChildren: () => import('./pages/cookies-page/cookies-page.module').then((m) => m.CookiesPageModule)
	},
	{
		path: 'politicas-de-redes-sociales',
		loadChildren: () =>
			import('./pages/social-network-page/social-network-page.module').then((m) => m.SocialNetworkPageModule)
	},
	{
		path: 'manual-arco',
		loadChildren: () => import('./pages/manual-bow-page/manual-bow-page.module').then((m) => m.ManualBowPageModule)
	},
	{
		path: 'formulario-arco',
		loadChildren: () =>
			import('./pages/request-arco-page/request-arco-page.module').then((m) => m.RequestArcoPageModule)
	},
	{
		path: 'terminos-y-condiciones-personal-shopper',
		loadChildren: () =>
			import(
				'./pages/terms-and-conditions-for-personal-shopper-page/terms-and-conditions-for-personal-shopper-page.module'
			).then((m) => m.PoliticsPageModule)
	},
	{
		path: 'uso-silla-ruedas-y-coches-para-bebes',
		loadChildren: () =>
			import(
				'./pages/use-wheelchairs-and-strollers-for-babies-page/use-wheelchairs-and-strollers-for-babies-page.module'
			).then((m) => m.PoliticsPageModule)
	},
	{
		path: 'politica-pet-friendly',
		loadChildren: () =>
			import('./pages/pet-friendly-policy-page/pet-friendly-policy-page.module').then((m) => m.PoliticsPageModule)
	},
	{
		path: 'politica-uso-lockers',
		loadChildren: () =>
			import('./pages/locker-use-policy-page/locker-use-policy-page.module').then((m) => m.PoliticsPageModule)
	},
	{
		path: 'politica-de-cookie',
		loadChildren: () =>
			import('./pages/cookies-policy-page/cookies-policy-page.module').then((m) => m.CookiesPolicyPageModule)
	},
	{
		path: 'politica-de-privacidad-de-redes-sociales',
		loadChildren: () =>
			import('./pages/social-media-policy-page/social-media-policy-page.module').then(
				(m) => m.SocialMediaPolicyPageModule
			)
	},
	{
		path: 'convocatoria-a-asamblea-general-de-obligacionistas',
		loadChildren: () =>
			import('./pages/call-to-general-assembly-page/call-to-general-assembly-page.module').then(
				(m) => m.CallToGeneralAssemblyPageModule
			)
	},
	{
		path: 'nota-de-prensa',
		loadChildren: () =>
			import('./pages/press-release-page/press-release-page.module').then((m) => m.PressReleasePageModule)
	},
	{
		path: 'codigo-de-etica',
		loadChildren: () =>
			import('./pages/code-of-ethics-page/code-of-ethics-page.module').then((m) => m.CodeOfEthicsPageModule)
	},
	{
		path: 'nuestros-directores',
		loadChildren: () =>
			import('./pages/our-directors-page/our-directors-page.module').then((m) => m.OurDirectorsPageModule)
	},
	{
		path: '**',
		loadChildren: () => import('./pages/notfound-page/notfound-page.module').then((m) => m.NotFoundPageModule)
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
	exports: [RouterModule]
})
export class AppRoutingModule {}

export const routesMenuPages = [
	{
		nombre: 'servicios de asistencia',
		url: '/servicios-de-asistencia'
	},
	{
		nombre: 'otros servicios',
		url: '/otros-servicios'
	},
	{
		nombre: 'Art Jockey',
		url: '/artJockey'
	},
	{
		nombre: 'sostenibilidad',
		url: '/sostenibilidad'
	}
];

export const routesMenuServices = [
	{
		nombre: 'Personal Shopper',
		url: '/personal-shopper'
	},
	{
		nombre: 'Giftealo',
		url: '/giftealo'
	},
	{
		nombre: 'Takeit',
		url: '/takeit'
	},
	{
		nombre: 'Guarda compras',
		url: '/guarda-compras'
	},
	{
		nombre: 'Objetos perdidos',
		url: '/objetos-perdidos'
	},
	{
		nombre: 'TikTok room',
		url: '/tiktok-room'
	},
	{
		nombre: 'Parking',
		url: '/parking'
	},
	{
		nombre: 'Estacionamiento para discapacitados',
		url: '/parking'
	},
	{
		nombre: 'Gasolinera eléctrica',
		url: '/parking'
	},
	{
		nombre: 'Estacionamiento para bicicletas',
		url: '/parking'
	}
];

import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { SesionComponent } from './sesion/sesion.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormSharedModule } from 'src/app/shared/form-shared.module';
import { SelectModule } from 'src/app/commons/elements-ui/select/select.module';
import { InputPhoneModule } from 'src/app/commons/elements-ui/input-phone/input-phone.module';
import { InputDocumentModule } from 'src/app/commons/elements-ui/input-document/input-document.module';
import { ModalMessageModule } from 'src/app/commons/elements-ui/modal-message/modal-message.module';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InputDateModule } from 'src/app/commons/elements-ui/input-date/input-date.module';

@NgModule({
	imports: [
		NgbModule,
		CommonModule,
		FormSharedModule,
		SelectModule,
		InputPhoneModule,
		InputDocumentModule,
		ModalMessageModule,
		RecaptchaV3Module,
		InfiniteScrollModule,
		InputDateModule
	],
	exports: [],
	declarations: [LoginComponent, RegisterComponent, SesionComponent],
	providers: [
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: environment.recaptcha
		}
	]
})
export class ModalAuthModule {}

export const dataGenero = [
	{ value: 0, name: 'Masculino' },
	{ value: 1, name: 'Femenino' },
	{ value: 2, name: 'Prefiero no decirlo' }
];

export const dataTipoDocumento = [
	{ value: 1, name: 'DNI' },
	{ value: 2, name: 'CARNE DE EXT.' }
];

import { Component, Input } from '@angular/core';
import { BannerService } from 'src/app/core/services/api/banner-api.service';

@Component({
	selector: 'app-side-card-set',
	templateUrl: './side-card-set.component.html',
	styleUrls: ['./side-card-set.component.scss']
})
export class SideCardSetComponent {
	@Input() stopSocialNetworks = false;
	@Input() bottomAbsolutePosition = 0;
	isActiveCardSet = false;

	constructor(public bannerService: BannerService) {}

	navigateUrl(link: string) {
		window.open(link, '_blank');
	}
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[appDateMask]'
})
export class DateMaskDirective {
	constructor(private el: ElementRef) {}

	@HostListener('input', ['$event']) onInputChange(event: any) {
		const initialVal = this.el.nativeElement.value;
		let val = initialVal.replace(/[^0-9]/g, '');
		if (val.length > 2) {
			val = val.substring(0, 2) + '/' + val.substring(2);
		}
		if (val.length > 5) {
			val = val.substring(0, 5) + '/' + val.substring(5);
		}
		if (val.length > 10) {
			val = val.substring(0, 10);
		}
		if (val !== initialVal) {
			this.el.nativeElement.value = val;
			event.stopPropagation();
		}
	}
}

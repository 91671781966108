/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
	selector: 'app-main-layout',
	templateUrl: './main-layout.component.html',
	styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
	footerDistanceTop = 0;
	footerHeight = 0;
	screenHeight = 0;
	stopSocialNetworks = false;
	pageInfoHeight = 890;
	bottomAbsolutePosition = 0;
	bottomAbsolutePositionSideCard = 0;

	ngOnInit() {}

	@HostListener('window:scroll')
	onWindowScroll(): void {
		this.getValues();
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.getValues();
	}

	getValues() {
		const screenHeight = window.screen.height;
		const footerDistanceTop = document.querySelector('.footer')?.getBoundingClientRect().top || 0;
		const pageInfoHeight = document.querySelector('.container_page')?.getBoundingClientRect().height || 0;
		this.bottomAbsolutePosition = 30 + screenHeight - pageInfoHeight;
		this.bottomAbsolutePositionSideCard = 260 + screenHeight - pageInfoHeight;
		this.stopSocialNetworks = footerDistanceTop - screenHeight + 30 <= 0 ? true : false;
	}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarSearcherComponent } from './navbar-searcher.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	exports: [NavbarSearcherComponent],
	declarations: [NavbarSearcherComponent],
	providers: []
})
export class NavBarSearchModule {}

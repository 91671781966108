import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SocialNetworksComponent } from './social-networks.component';

@NgModule({
	imports: [CommonModule],
	exports: [SocialNetworksComponent],
	declarations: [SocialNetworksComponent],
	providers: []
})
export class SocialNetworkModule {}

/* eslint-disable @angular-eslint/no-output-native */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
	@Input() text!: string;
	@Input() disabled = false;
	@Input() size?: string;
	@Input() color = 'primary';
	@Input() icon?: string;
	@Input() srcImg?: string;
	@Input() right?: boolean;
	@Input() left?: boolean;
	@Input() random?: boolean;
	@Input() type = 'button';
	@Input() classes?: string = '';
	@Input() isRecover = false;
	@Input() isWhite?: boolean = false;
	@Input() isInitDisabled?: boolean = false;
	@Input() bgDisabled?: boolean = false;
	@Input() link = '';
	@Input() blank = true;
	@Output() click = new EventEmitter<MouseEvent>();

	arrayColor = [' hover-primary', ' hover-secondary', ' hover-green', ' hover-blue'];
	class = '';
	hoverColor!: string;
	indexHover = -1;
	ngOnInit(): void {
		this.class = `${this.classes} btn ${this.size} ${this.right ? 'icon-left ' : ''} ${this.left ? 'icon-right ' : ''}`;
		this.hoverColor = ` hover-${this.color == 'secondary' ? 'red' : this.color}`;

		if (this.color?.includes('outline')) {
			this.arrayColor = [
				' hover-outline-primary',
				' hover-outline-secondary',
				' hover-outline-green',
				' hover-outline-blue'
			];
		}
	}

	onClickButton(event: MouseEvent) {
		if (this.link !== '') {
			console.log('href');
		}
		this.click.emit(event);
	}

	onMouseEnter() {
		if (this.random) {
			this.indexHover++;
			if (this.indexHover == 4) {
				this.indexHover = 0;
			}
			this.hoverColor = this.arrayColor[this.indexHover];
		}
	}
}

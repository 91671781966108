/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URL_BACKEND } from '../../config/url';
import { INotification, INotificationListResponse } from '../../interfaces/notirfication.interface';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class NotificationService {
	constructor(private _httpClient: HttpClient, private _authService: AuthService) {}

	getNotifications(): Observable<INotification[]> {
		const clienteId = this._authService.getIdUser();
		const url = `${URL_BACKEND}/notification/get`;
		return this._httpClient.post<INotification[]>(url, { clienteId: clienteId });
	}

	getListNotifications(page: number, offset: number): Observable<INotificationListResponse> {
		const clienteId = this._authService.getIdUser();
		const url = `${URL_BACKEND}/notification/list?page=${page}&offset=${offset}`;
		return this._httpClient.post<INotificationListResponse>(url, { clienteId: clienteId, mostrar: true });
	}

	updateNotification(detalleId: number): Observable<INotification[]> {
		const clienteId = this._authService.getIdUser();
		const url = `${URL_BACKEND}/notification?clientId=${clienteId}&detalleId=${detalleId}`;
		return this._httpClient.put<INotification[]>(url, '');
	}
}

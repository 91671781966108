import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, animate, transition, state } from '@angular/animations';

interface IMenuItem {
	id: number;
	name: string;
	url: string;
	submenu: Array<{ id: number; name: string; url: string }>;
}

@Component({
	selector: 'app-navbar-options',
	templateUrl: './navbar-options.component.html',
	styleUrls: ['./navbar-options.component.scss'],
	animations: [
		trigger('showSubMenu', [
			state(
				'state1',
				style({
					height: '0px',
					color: 'red'
				})
			),
			state(
				'state2',
				style({
					height: '84px'
				})
			),
			transition('state1 <=> state2', animate(1000))
		])
	]
})
export class NavbarOptionsComponent {
	@Input() listMenu: IMenuItem[] = [];
	activeSubmenu = '';
	@Output() clickLink = new EventEmitter<boolean>();
	@Output() clickSuggestionSearcher = new EventEmitter<boolean>();
	constructor(private _router: Router) {}

	openSubMenu(name: string) {
		this.activeSubmenu = this.activeSubmenu !== name ? name : '';
	}

	navigatePage(url: string): void {
		this._router.navigateByUrl(url);
		this.clickLink.emit(false);
	}

	getShowContainerClass(name: string) {
		let classShow = 'show__container__';
		if (name === 'Directorio') {
			classShow = `${classShow}directory`;
		}
		if (name === 'Servicios') {
			classShow = `${classShow}services`;
		}
		if (name === 'Cultura') {
			classShow = `${classShow}culture`;
		}
		return classShow;
	}

	clickSuggestion(ev: boolean) {
		this.clickSuggestionSearcher.emit(ev);
	}
}

<div class="container__pagination">
	<ul class="pagination">
		<li>
			<a
				class="page-link icon__stroke"
				[ngClass]="{ disabled: disabledLeftArrow }"
				(click)="changePage(currentPage - 1)"
			>
				<svg width="22" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M15 19.9201L8.47997 13.4001C7.70997 12.6301 7.70997 11.3701 8.47997 10.6001L15 4.08008"
						stroke-width="1.5"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</a>
		</li>
		<li *ngFor="let page of totalPages">
			<a
				class="page-link"
				[ngClass]="{ active: validate(page.isNumber, page.pNumber) }"
				(click)="page.pageFunction()"
				>{{ page.pLabel }}</a
			>
		</li>
		<li>
			<a
				class="page-link icon__stroke"
				[ngClass]="{ disabled: disabledRightArrow }"
				(click)="changePage(currentPage + 1)"
			>
				<svg width="22" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M8.91003 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91003 4.08008"
						stroke-width="1.5"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</a>
		</li>
	</ul>
</div>

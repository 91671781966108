/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map, Subject, takeUntil, tap } from 'rxjs';
import { dataFlags } from './data';

interface IPhone {
	name: string;
	dial_code: string;
	code: string;
	numberExample: string;
	dial: string;
	codeLower: string;
}

@Component({
	selector: 'app-input-phone',
	templateUrl: './input-phone.component.html',
	styleUrls: ['./input-phone.component.scss']
})
export class InputPhoneComponent implements OnInit, OnDestroy {
	showItems = false;
	flags: IPhone[] = dataFlags;
	textPlaceholder = '';
	form!: FormGroup;
	regexInitNumber = 0;
	regexLength = 0;
	regexPhone!: RegExp;
	maxlength = 9;
	@Input() dialCode!: string;
	@Input() numberPhone = '';
	@Input() isPhone = false;
	@Input() isRequired = true;
	@Input() showRequired = false;
	@Input() placeholderInput = '';
	@Input() disabled = false;
	@Output() outFocus = new EventEmitter<any>();
	flagSeleted!: IPhone;

	constructor(private formBuilder: FormBuilder) {}

	valueSearch = new FormControl('');
	private destroy$ = new Subject<unknown>();

	ngOnInit(): void {
		const index = dataFlags.findIndex((item) => item.dial_code == this.dialCode);
		this.flagSeleted = dataFlags[index !== -1 ? index : 2];
		this.onChangeSearch();
		this.setRegexInput();
		this.form = this.formBuilder.group({
			valuePhone: [this.numberPhone, [Validators.required, Validators.pattern(this.regexPhone)]]
		});
	}

	showItemsCountries() {
		this.showItems = !this.showItems;
	}

	setRegexInput() {
		this.textPlaceholder = this.placeholderInput ? this.placeholderInput : this.flagSeleted.numberExample;
		this.regexInitNumber = Number(this.flagSeleted.numberExample.charAt(0));
		this.regexLength = this.flagSeleted.numberExample.length - 1;
		this.maxlength = this.flagSeleted.dial == '51' ? 9 : 12;
		this.regexPhone = new RegExp(`[${this.regexInitNumber}]{1}[0-9]{${this.regexLength}}$`);
	}

	private onChangeSearch(): void {
		this.valueSearch.valueChanges
			.pipe(
				map((search) => search?.toLowerCase().trim()),
				map((search) => {
					if (search == undefined || search?.length <= 1) {
						this.flags = dataFlags;
					}
					return search;
				}),
				debounceTime(500),
				distinctUntilChanged(),
				filter((search) => search !== '' && search !== undefined && search?.length > 1),
				tap((search) => {
					if (search !== undefined) {
						this.flags = dataFlags.filter((item) => item.name.toLowerCase().includes(search));
					}
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.destroy$.next({});
		this.destroy$.complete();
	}

	clickClear() {
		this.valueSearch.setValue('');
		this.form.reset();
	}

	clickItem(item: IPhone) {
		this.showItems = false;
		this.clickClear();
		this.flagSeleted = item;
		this.setRegexInput();
		this.form.controls['valuePhone'].clearValidators();
		this.form.controls['valuePhone'].addValidators([Validators.required, Validators.pattern(this.regexPhone)]);
	}

	getPhoneSelected() {
		this.form.markAllAsTouched();
		return {
			phone: this.phoneField!.value,
			dial: this.flagSeleted.dial_code,
			isValid: this.phoneField!.hasError('required') || this.phoneField!.hasError('pattern') ? false : true
		};
	}

	onFocusOutEvent() {
		const data = this.getPhoneSelected();
		this.outFocus.emit(data);
	}

	markError() {
		this.phoneField.markAsTouched();
		this.phoneField.updateValueAndValidity();
	}

	resetForm() {
		this.clickClear();
		this.form.reset();
		this.flagSeleted = dataFlags[2];
		this.setRegexInput();
	}

	get phoneField(): AbstractControl {
		return this.form.get('valuePhone')!;
	}
}

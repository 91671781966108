<div class="container__cookies" *ngIf="isShow">
	<section>
		<div class="container__cookies__contend">
			<span class="container__cookies__contend__title"> Este sitio web usa cookies </span>
			<div class="container__cookies__contend__info">
				<span class="container__cookies__contend__info__text">
					Las cookies de este sitio web son usadas para personalizar el contenido y los anuncios, ofrecer funciones de
					redes sociales y analizar el tráfico. Si continúas navegando entendemos que aceptas nuestra
					<a class="link" href="/politicas-de-cookies" [target]="'_blank'">Política de cookies.</a>
				</span>
				<div class="container__cookies__contend__info__acept" (click)="acceptCookies()">
					<span>Aceptar</span>
				</div>
			</div>
			<div class="container__cookies__contend__line"></div>
		</div>
	</section>
</div>

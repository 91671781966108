/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-output-native */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-alerta',
	templateUrl: './alerta.component.html',
	styleUrls: ['./alerta.component.scss']
})
export class AlertaComponent implements OnInit {
	@Input() title!: string;
	@Input() type = 'alert-danger';
	@Input() icon?: string;
	@Input() messages?: Array<string> = [];
	@Input() closeTime = false;
	@Input() isPage = false;
	@Input() time = 4000;
	@Output() close = new EventEmitter<MouseEvent>();

	constructor() {}

	ngOnInit(): void {
		if (this.closeTime) {
			setTimeout(() => {
				this.close.emit();
			}, this.time);
		}
	}

	onClose(event: MouseEvent) {
		this.close.emit();
	}
}

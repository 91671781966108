/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { URL_BACKEND } from '../../config/url';
import { IDetailService, IServiceRequest, ICategoryService, IService } from '../../interfaces/service.interface';
import { LocalResolverService } from '../api/local-resolver.service';
@Injectable({ providedIn: 'root' })
export class JockeyServices {
	constructor(private _httpClient: HttpClient, private _localHttp: LocalResolverService) {}

	getServiceListByCategory(page: number, offset: number, categoria: string): Observable<IServiceRequest> {
		const url = `${URL_BACKEND}/organization/service/list?page=${page}&offset=${offset}`;
		return this._httpClient.post<IServiceRequest>(url, {
			categoria: categoria
		});
	}

	getServiceByName(nombre: string): Observable<IDetailService> {
		const url = `${URL_BACKEND}/organization/service/show?nombre=${nombre}`;
		return this._httpClient.get<IDetailService>(url).pipe(
			tap((res) => {
				if (res.id !== 0) {
					res.arrayDescripcion = res.descripcion.split(/\n|\\n/);
					res.bloqueInformativo.map((bloque) => {
						bloque.arrayDescripcion = bloque.descripcion.split(/\n|\\n/);
						return bloque;
					});
				}
				return res;
			})
		);
	}

	getAllServices(): Observable<IServiceRequest> {
		const url = `${URL_BACKEND}/organization/service/list?page=1&offset=25`;
		return this._httpClient.post<IServiceRequest>(url, null);
	}

	getCategoriesService(): Observable<ICategoryService[]> {
		const url = `${URL_BACKEND}/service/category`;
		return this._httpClient.get<ICategoryService[]>(url);
	}

	getServicesByCategory(category: string): Observable<IService[]> {
		const url = `${URL_BACKEND}/organization/service/get`;
		return this._httpClient.post<IService[]>(url, { Categoria: category });
	}
}

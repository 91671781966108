import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SideCardSetComponent } from './side-card-set.component';

@NgModule({
	imports: [CommonModule],
	exports: [SideCardSetComponent],
	declarations: [SideCardSetComponent],
	providers: []
})
export class SideCardSetModule {}

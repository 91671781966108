import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/api/auth.service';
import { LinksExtService } from 'src/app/core/services/api/links-ext-api.service';

@Component({
	selector: 'app-cookies',
	templateUrl: './cookies.component.html',
	styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {
	constructor(private _authService: AuthService, public _linksService: LinksExtService) {}
	isShow = false;
	ngOnInit(): void {
		this.isShow = this._authService.getCookies() !== null ? false : true;
	}

	acceptCookies() {
		this._authService.setCookies();
		this.isShow = false;
	}
}

export const dataMenuMyAccount = [
	{
		id: 1,
		name: 'Información',
		url: '/mi-cuenta',
		icon: 'user'
	},
	{
		id: 2,
		name: 'Favoritos',
		url: '/mi-cuenta/favoritos',
		icon: 'heart'
	},
	{
		id: 3,
		name: 'Wishlist',
		url: '/mi-cuenta/lista-de-deseos',
		icon: 'star'
	},
	{
		id: 4,
		name: 'Calendario',
		url: '/mi-cuenta/calendario',
		icon: 'calendar'
	},
	{
		id: 5,
		name: 'Notificaciones',
		url: '/mi-cuenta/notificaciones',
		icon: 'bell'
	},
	{
		id: 6,
		name: 'Configuración',
		url: '/mi-cuenta/configuracion',
		icon: 'setting'
	}
];

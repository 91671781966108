import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './main-layout.component';
import { RouterModule } from '@angular/router';
import { SocialNetworkModule } from '../../elements-ui/social-networks/social-networks.module';
import { ModalAuthModule } from './components/modal/modal.module';
import { FormSharedModule } from 'src/app/shared/form-shared.module';
import { FooterModule } from '../../elements-ui/footer/footer.module';
import { NavBarModule } from '../../elements-ui/navbar/navbar.module';
import { CookiesModule } from '../../elements-ui/cookies/cookies.module';
import { SideCardSetModule } from '../../elements-ui/side-card-set/side-card-set.module';

@NgModule({
	declarations: [MainLayoutComponent],
	imports: [
		CommonModule,
		RouterModule,
		SocialNetworkModule,
		SideCardSetModule,
		ModalAuthModule,
		FormSharedModule,
		FooterModule,
		NavBarModule,
		CookiesModule
	],
	exports: [MainLayoutComponent]
})
export class MainLayoutModule {}

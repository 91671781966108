/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URL_BACKEND } from '../../config/url';

@Injectable({ providedIn: 'root' })
export class SuggestionApiService {
	constructor(private _httpClient: HttpClient) {}

	getSuggestionByWord(word: string): Observable<any> {
		const url = `${URL_BACKEND}/content/search/store/filter?nombre=${word}`;
		return this._httpClient.get<any>(url);
	}

	getSuggestionToGeneralSearch(word: string): Observable<any> {
		const url = `${URL_BACKEND}/content/search/general/filter?nombre=${word}`;
		return this._httpClient.get<any>(url);
	}
}

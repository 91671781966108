<app-navbar></app-navbar>
<div class="container_page">
	<app-side-card-set
		[stopSocialNetworks]="stopSocialNetworks"
		[bottomAbsolutePosition]="bottomAbsolutePositionSideCard"
	></app-side-card-set>
	<app-social-networks
		[stopSocialNetworks]="stopSocialNetworks"
		[bottomAbsolutePosition]="bottomAbsolutePosition"
	></app-social-networks>
	<router-outlet></router-outlet>
</div>
<app-cookies></app-cookies>
<app-footer class="footer"></app-footer>

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Nosotros } from 'src/app/core/interfaces/about.interface';
import { AboutService } from 'src/app/core/services/api/about-api.service';
import { dataFooter } from './data';
import { LinksExtService } from 'src/app/core/services/api/links-ext-api.service';
@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	constructor(private _router: Router, public _aboutService: AboutService, public _linksService: LinksExtService) {}
	listMenu: any[] = [];
	dataLinks!: Nosotros;
	myDate: any = new Date();
	linkSocialMediaPolicy = '';
	linkCookiePolicy = '';

	ngOnInit(): void {
		this.listMenu = dataFooter;
		this.getInfoAbout();
		this.getExternalLinks();
		document.cookie = 'cookiesAccepted=true';
	}

	navigateUrl(link: string) {
		if (link.charAt(0) === '/') {
			this._router.navigateByUrl(link);
		} else {
			window.open(link, '_blank');
		}
	}

	getInfoAbout() {
		this._aboutService.getAboutJockey().subscribe({
			next: (res) => {
				this._aboutService.dataAbout = res;
				this.dataLinks = res.informacion;
				this._aboutService.dataLinks = this.dataLinks;
			},
			error: (e) => {
				console.log(e);
			}
		});
	}

	getExternalLinks() {
		this._linksService.getLinks().subscribe({
			next: (res) => {
				this._linksService.dataExternalLinks = res;
				this.listMenu.map((item, index) => {
					if (index !== 1) {
						item.links.map((link: any) => {
							if (link.url == '#') {
								const newLink = this._linksService.searchLink(link.name);
								link.url = newLink;
							}
							return link;
						});
					}
					return item;
				});
				this.linkSocialMediaPolicy =
					res.find((link) => link.nombre === 'Politicas de Privacidad de Redes Sociales')?.url || '';
				this.linkCookiePolicy = res.find((link) => link.nombre === 'Política de Cookie')?.url || '';
			}
		});
	}
}

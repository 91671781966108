/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Component,
	EventEmitter,
	Input,
	Output,
	OnInit,
	OnDestroy,
	ViewChild,
	ElementRef,
	AfterViewInit
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, filter, tap, takeUntil } from 'rxjs/operators';
import { SuggestionApiService } from 'src/app/core/services/api/suggestion-api.service';
import { ISuggestionGeneralSearch } from 'src/app/core/interfaces/suggestion.interface';
import { routesMenuPages, routesMenuServices } from './menu-items';
import { encodeUrl } from 'src/app/core/functions/encodeUrl';
@Component({
	selector: 'app-navbar-searcher',
	templateUrl: './navbar-searcher.component.html',
	styleUrls: ['./navbar-searcher.component.scss']
})
export class NavbarSearcherComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() placeholder = 'Buscar';
	@Output() clickOpenSearch = new EventEmitter<boolean>();
	@Output() clickSuggestionOutput = new EventEmitter<boolean>();
	@ViewChild('generalSearchInput', { static: false })
	generalSearchInput!: ElementRef;

	listSuggestions: ISuggestionGeneralSearch[] = [];
	private destroy$ = new Subject<unknown>();

	valueSearch = new FormControl('');

	constructor(public _suggestionnService: SuggestionApiService, private _router: Router) {}

	ngOnInit(): void {
		this.onChangeSearch();
	}

	ngAfterViewInit() {
		this.generalSearchInput.nativeElement.focus();
	}

	clickClose() {
		this.valueSearch.setValue('');
		this.clickOpenSearch.emit(false);
	}

	private onChangeSearch(): void {
		this.valueSearch.valueChanges
			.pipe(
				map((search) => search?.toLowerCase().trim()),
				map((search) => {
					if (search == undefined || search?.length <= 1) {
						this.listSuggestions = [];
					}
					return search;
				}),
				debounceTime(250),
				distinctUntilChanged(),
				filter((search) => search !== '' && search !== undefined && search?.length > 1),
				tap((search) => this.onSearchService(search)),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	getUrlToItem(item: ISuggestionGeneralSearch) {
		switch (item.tipo) {
			case 'Tienda':
				const codeNameStore = encodeUrl(item.nombre);
				return `/tiendas/${codeNameStore}`;
			case 'Gastronomia':
				const codeNameRestaurant = encodeUrl(item.nombre);
				return `/gastronomia/${codeNameRestaurant}`;
			case 'Centro Financiero':
				const codeNameBank = encodeUrl(item.nombre);
				return `/centros-finacieros/${codeNameBank}`;
			case 'Evento':
				return `/eventos/${item.id}`;
			case 'Descuento':
				return `/descuentos/${item.id}`;
			case 'Campaña':
				return `/campana/${item.id}`;
			case 'Pagina':
				const urlPagina = routesMenuPages.find((menuItem) => menuItem.nombre === item.nombre)?.url;
				if (urlPagina) return urlPagina;
				return '/';
			case 'Servicio-asistencia':
				return '/servicios-de-asistencia/' + encodeUrl(item.nombre);
			case 'Servicio-adicional':
				const url = routesMenuServices.find((menuItem) => menuItem.nombre === item.nombre)?.url;
				if (url) return url;
				return '/servicio-adicional/' + encodeUrl(item.nombre);
			case 'Servicio-estacionamiento':
				return '/parking';
			case 'Servicio-otros':
				return '/otros-servicios';
			default:
				return item.url;
		}
	}

	onSearchService(search = ''): void {
		const arraySuggestions: ISuggestionGeneralSearch[] = [];
		if (search) {
			this._suggestionnService.getSuggestionToGeneralSearch(search).subscribe((res) => {
				search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
				const listResultAll = res.listaGeneral ? res.listaGeneral : [];
				listResultAll.reduce((results: ISuggestionGeneralSearch[], item: ISuggestionGeneralSearch) => {
					const word = item.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
					const coincidence: any = word.match(new RegExp(search, 'gi'));
					const strRegExp = new RegExp(search, 'gi');
					let html;
					if (coincidence?.length > 1) {
						let wordAux = JSON.parse(JSON.stringify(word));
						for (let i = 0; i < coincidence.length; i++) {
							const coincidencia = new RegExp(coincidence[i], 'gi');
							wordAux = wordAux.replace(coincidencia, '<strong>$&</strong>');
						}
						html = wordAux;
					} else html = word.replace(strRegExp, `<strong>${coincidence}</strong>`);
					if (html.charAt(0) == '<') {
						const letter = html.charAt(8);
						const chars: any[] = html.split('');
						chars[8] = letter.toUpperCase();
						html = chars.join('');
					}
					item.url = this.getUrlToItem(item);
					results.push({ ...item, wordHtml: html });
					return results;
				}, arraySuggestions);
			});
			this.listSuggestions = arraySuggestions;
		} else {
			this.listSuggestions = [];
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next({});
		this.destroy$.complete();
	}

	clickSuggestion(url: string) {
		this._router.navigateByUrl(url);
		/*this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this._router.navigateByUrl(url);
		});*/
		this.clickClose();
		this.clickSuggestionOutput.emit(false);
	}
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
	selector: 'app-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InputComponent implements OnInit {
	@Input() icon?: string;
	@Input() placeholder?: string;
	@Input() label?: string;
	@Input() value?: string = '';
	@Input() type?: string = 'text';
	@Input() class?: string;
	@Input() name?: any = 'input';
	@Input() password?: boolean = false;
	@Input() icon2?: string;
	@Input() labelError?: string = '';
	@Input() isGroup = false;
	@Input() labelStroke = false;
	@Input() disabled = false;
	@Input() isRequired = false;
	@Input() maxlength = 250;
	@Input() isOnlyText = false;
	@Output() focusEmail = new EventEmitter();

	isShowPassword = false;

	ngOnInit(): void {
		//console.log(this.formControl)
	}

	showPassword() {
		this.isShowPassword = !this.isShowPassword;
	}

	cleanErrorEmail() {
		if (
			this.labelError == 'Este correo ya está registrado' ||
			this.labelError == 'La contraseña actual es incorrecta'
		) {
			this.labelError = '';
			this.focusEmail.emit();
		}
	}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalMessageComponent } from './modal-message.component';
import { ButtonModule } from '../button/button.module';

@NgModule({
	imports: [CommonModule, ButtonModule],
	exports: [ModalMessageComponent],
	declarations: [ModalMessageComponent],
	providers: []
})
export class ModalMessageModule {}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { MainLayoutModule } from './commons/layouts/main-layout/main-layout.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MyAccountLayoutModule } from './commons/layouts/my-account-layout/my-account-layout.module';
import { CookieService } from 'ngx-cookie-service';
import { CanActivateViaAuthGuard } from './core/guards/auth.guard';
import { AuthInterceptorService } from './core/interceptors/authorization.interceptor';
import { OnlineStatusModule } from 'ngx-online-status';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		MainLayoutModule,
		MyAccountLayoutModule,
		HttpClientModule,
		BrowserAnimationsModule,
		NgbModule,
		ReactiveFormsModule,
		FormsModule,
		OnlineStatusModule
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent],
	providers: [
		CookieService,
		CanActivateViaAuthGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true
		}
	]
})
export class AppModule {}

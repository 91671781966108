<div class="container__modal__confirmation">
	<div class="modal__info">
		<div class="modal__header">
			<div class="icon">
				<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect width="48" height="48" rx="24" fill="#FFE9F0" />
					<path
						d="M22.7509 14.4503C23.4509 13.8603 24.5809 13.8603 25.2609 14.4503L26.8409 15.8003C27.1409 16.0503 27.7109 16.2603 28.1109 16.2603H29.8109C30.8709 16.2603 31.7409 17.1303 31.7409 18.1903V19.8903C31.7409 20.2903 31.9509 20.8503 32.2009 21.1503L33.5509 22.7303C34.1409 23.4303 34.1409 24.5603 33.5509 25.2403L32.2009 26.8203C31.9509 27.1203 31.7409 27.6803 31.7409 28.0803V29.7803C31.7409 30.8403 30.8709 31.7103 29.8109 31.7103H28.1109C27.7109 31.7103 27.1509 31.9203 26.8509 32.1703L25.2709 33.5203C24.5709 34.1103 23.4409 34.1103 22.7609 33.5203L21.1809 32.1703C20.8809 31.9203 20.3109 31.7103 19.9209 31.7103H18.1709C17.1109 31.7103 16.2409 30.8403 16.2409 29.7803V28.0703C16.2409 27.6803 16.0409 27.1103 15.7909 26.8203L14.4409 25.2303C13.8609 24.5403 13.8609 23.4203 14.4409 22.7303L15.7909 21.1403C16.0409 20.8403 16.2409 20.2803 16.2409 19.8903V18.2003C16.2409 17.1403 17.1109 16.2703 18.1709 16.2703H19.9009C20.3009 16.2703 20.8609 16.0603 21.1609 15.8103L22.7509 14.4503Z"
						stroke="#FF1A5D"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M24 20.1328V24.9628"
						stroke="#FF1A5D"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M23.9941 28H24.0031"
						stroke="#FF1A5D"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</div>
			<span class="title">Parece que no hay conexión.</span>
			<span class="subtitle">Espera un momento, por favor </span>
			<div class="dot-flashing"></div>
		</div>
	</div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotResultComponent } from './not-result.component';

@NgModule({
	imports: [CommonModule],
	exports: [NotResultComponent],
	declarations: [NotResultComponent],
	providers: []
})
export class NotResultModule {}

<label [class.disabled]="disabled"
	>Documento de identidad <span *ngIf="isRequired && isShowRequired" class="span__label__required">*</span></label
>
<div
	class="container__document"
	[class.isBootom]="isBootom"
	[class.isError]="documentField.touched && documentField!.invalid && isRequired"
>
	<div
		class="container__document__select"
		(click)="showItemsSelect()"
		[class.isCarnet]="title !== 'DNI'"
		[class.disabled]="disabled"
	>
		<span class="container__document__select__text" [class.disabled]="disabled">
			{{ title }}
		</span>
		<img src="assets/icons/arrow-down-dark.svg" alt="v" />
	</div>

	<div class="container__document__select__items" *ngIf="showItems">
		<div
			class="container__document__select__items__item"
			*ngFor="let item of listItems"
			(click)="clickItem(item); inputNumber.focus()"
		>
			{{ item.name }}
		</div>
	</div>

	<div class="container__document__input">
		<form [formGroup]="form">
			<input
				#inputNumber
				oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
				[type]="type"
				onwheel="return false;"
				[placeholder]="placeholder"
				formControlName="valueDocument"
				[class.error]="documentField.touched && documentField!.invalid && isRequired"
				(focusout)="onFocusOutEvent()"
				[readonly]="disabled"
				[maxlength]="type === 'number' ? 8 : 15"
			/>
		</form>
		<span *ngIf="documentField.touched && documentField!.hasError('required') && isRequired"
			>Número es obligatorio</span
		>
		<span *ngIf="documentField.touched && documentField!.hasError('pattern') && isRequired">Número inválido</span>
	</div>
</div>

/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, map } from 'rxjs';
import { URL_BACKEND } from '../../config/url';
import { IBanner } from '../../interfaces/banner.interface';

@Injectable({ providedIn: 'root' })
export class BannerService {
	isErrorPage = false;
	isBannerActive = false;
	bannerChangeState: Subject<boolean> = new Subject<boolean>();
	hiddenWidgetSocial = false;
	url = `${URL_BACKEND}/content/banner/get`;

	constructor(private _httpClient: HttpClient) {
		this.bannerChangeState.subscribe((value) => {
			this.isBannerActive = value;
		});
	}

	toggleBannerState(value: boolean) {
		this.bannerChangeState.next(value);
	}

	getBanners(): Observable<any[]> {
		return this._httpClient.post<IBanner[]>(this.url, '');
	}

	getBannerByPage(page: string): Observable<IBanner[]> {
		return this._httpClient
			.post<IBanner[]>(this.url, {
				pagina: page
			})
			.pipe(
				map((res) => {
					const filteredData = res.filter((banner) => banner.estado === 'Activa');
					return filteredData;
				})
			);
	}

	getVideo(url: string): Observable<any> {
		return this._httpClient.get(url, { responseType: 'blob' });
	}
}

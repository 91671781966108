export const dataFlags = [
	{
		name: 'Argentina',
		dial_code: '+54',
		code: 'AR',
		numberExample: '91123456789',
		dial: '54',
		codeLower: 'ar'
	},
	{
		name: 'Chile',
		dial_code: '+56',
		code: 'CL',
		numberExample: '221234567',
		dial: '56',
		codeLower: 'cl'
	},
	{
		name: 'Peru',
		dial_code: '+51',
		code: 'PE',
		numberExample: '912345678',
		dial: '51',
		codeLower: 'pe'
	},
	{
		name: 'Venezuela',
		dial_code: '+58',
		code: 'VE',
		numberExample: '4121234567',
		dial: '58',
		codeLower: 've'
	},
	{
		name: 'Bolivia',
		dial_code: '+591',
		code: 'BO',
		numberExample: '71234567',
		dial: '591',
		codeLower: 'bo'
	},
	{
		name: 'Ecuador',
		dial_code: '+593',
		code: 'EC',
		numberExample: '991234567',
		dial: '593',
		codeLower: 'ec'
	},
	{
		name: 'Brazil',
		dial_code: '+55',
		code: 'BR',
		numberExample: '11961234567',
		dial: '55',
		codeLower: 'br'
	},
	{
		name: 'Colombia',
		dial_code: '+57',
		code: 'CO',
		numberExample: '3211234567',
		dial: '57',
		codeLower: 'co'
	},
	{
		name: 'Andorra',
		dial_code: '+376',
		code: 'AD',
		numberExample: '312345',
		dial: '376',
		codeLower: 'ad'
	},
	{
		name: 'United Arab Emirates',
		dial_code: '+971',
		code: 'AE',
		numberExample: '501234567',
		dial: '971',
		codeLower: 'ae'
	},
	{
		name: 'Afghanistan',
		dial_code: '+93',
		code: 'AF',
		numberExample: '701234567',
		dial: '93',
		codeLower: 'af'
	},
	{
		name: 'Antigua and Barbuda',
		dial_code: '+1268',
		code: 'AG',
		numberExample: '2684641234',
		dial: '1268',
		codeLower: 'ag'
	},
	{
		name: 'Anguilla',
		dial_code: '+1264',
		code: 'AI',
		numberExample: '2642351234',
		dial: '1264',
		codeLower: 'ai'
	},
	{
		name: 'Albania',
		dial_code: '+355',
		code: 'AL',
		numberExample: '662123456',
		dial: '355',
		codeLower: 'al'
	},
	{
		name: 'Armenia',
		dial_code: '+374',
		code: 'AM',
		numberExample: '77123456',
		dial: '374',
		codeLower: 'am'
	},
	{
		name: 'Angola',
		dial_code: '+244',
		code: 'AO',
		numberExample: '923123456',
		dial: '244',
		codeLower: 'ao'
	},
	{
		name: 'AmericanSamoa',
		dial_code: '+1684',
		code: 'AS',
		numberExample: '6847331234',
		dial: '1684',
		codeLower: 'as'
	},
	{
		name: 'Austria',
		dial_code: '+43',
		code: 'AT',
		numberExample: '664123456',
		dial: '43',
		codeLower: 'at'
	},
	{
		name: 'Australia',
		dial_code: '+61',
		code: 'AU',
		numberExample: '412345678',
		dial: '61',
		codeLower: 'au'
	},
	{
		name: 'Aruba',
		dial_code: '+297',
		code: 'AW',
		numberExample: '5601234',
		dial: '297',
		codeLower: 'aw'
	},
	{
		name: 'Aland Islands',
		dial_code: '+358',
		code: 'AX',
		numberExample: '412345678',
		dial: '358',
		codeLower: 'ax'
	},
	{
		name: 'Azerbaijan',
		dial_code: '+994',
		code: 'AZ',
		numberExample: '401234567',
		dial: '994',
		codeLower: 'az'
	},
	{
		name: 'Barbados',
		dial_code: '+1246',
		code: 'BB',
		numberExample: '2462501234',
		dial: '1246',
		codeLower: 'bb'
	},
	{
		name: 'Bangladesh',
		dial_code: '+880',
		code: 'BD',
		numberExample: '1812345678',
		dial: '880',
		codeLower: 'bd'
	},
	{
		name: 'Belgium',
		dial_code: '+32',
		code: 'BE',
		numberExample: '470123456',
		dial: '32',
		codeLower: 'be'
	},
	{
		name: 'Burkina Faso',
		dial_code: '+226',
		code: 'BF',
		numberExample: '70123456',
		dial: '226',
		codeLower: 'bf'
	},
	{
		name: 'Bulgaria',
		dial_code: '+359',
		code: 'BG',
		numberExample: '48123456',
		dial: '359',
		codeLower: 'bg'
	},
	{
		name: 'Bahrain',
		dial_code: '+973',
		code: 'BH',
		numberExample: '36001234',
		dial: '973',
		codeLower: 'bh'
	},
	{
		name: 'Burundi',
		dial_code: '+257',
		code: 'BI',
		numberExample: '79561234',
		dial: '257',
		codeLower: 'bi'
	},
	{
		name: 'Benin',
		dial_code: '+229',
		code: 'BJ',
		numberExample: '90011234',
		dial: '229',
		codeLower: 'bj'
	},
	{
		name: 'Bermuda',
		dial_code: '+1441',
		code: 'BM',
		numberExample: '4413701234',
		dial: '1441',
		codeLower: 'bm'
	},
	{
		name: 'Brunei Darussalam',
		dial_code: '+673',
		code: 'BN',
		numberExample: '7123456',
		dial: '673',
		codeLower: 'bn'
	},
	{
		name: 'Bahamas',
		dial_code: '+1242',
		code: 'BS',
		numberExample: '2423591234',
		dial: '1242',
		codeLower: 'bs'
	},
	{
		name: 'Bhutan',
		dial_code: '+975',
		code: 'BT',
		numberExample: '17123456',
		dial: '975',
		codeLower: 'bt'
	},
	{
		name: 'Botswana',
		dial_code: '+267',
		code: 'BW',
		numberExample: '71123456',
		dial: '267',
		codeLower: 'bw'
	},
	{
		name: 'Belarus',
		dial_code: '+375',
		code: 'BY',
		numberExample: '294911911',
		dial: '375',
		codeLower: 'by'
	},
	{
		name: 'Belize',
		dial_code: '+501',
		code: 'BZ',
		numberExample: '6221234',
		dial: '501',
		codeLower: 'bz'
	},
	{
		name: 'Canada',
		dial_code: '+1',
		code: 'CA',
		numberExample: '5062345678',
		dial: '1',
		codeLower: 'ca'
	},
	{
		name: 'Cocos',
		dial_code: '+61',
		code: 'CC',
		numberExample: '412345678',
		dial: '61',
		codeLower: 'cc'
	},
	{
		name: 'African Republic',
		dial_code: '+236',
		code: 'CF',
		numberExample: '70012345',
		dial: '236',
		codeLower: 'cf'
	},
	{
		name: 'Congo',
		dial_code: '+242',
		code: 'CG',
		numberExample: '061234567',
		dial: '242',
		codeLower: 'cg'
	},
	{
		name: 'Switzerland',
		dial_code: '+41',
		code: 'CH',
		numberExample: '781234567',
		dial: '41',
		codeLower: 'ch'
	},
	{
		name: "Cote d'Ivoire",
		dial_code: '+225',
		code: 'CI',
		numberExample: '01234567',
		dial: '225',
		codeLower: 'ci'
	},
	{
		name: 'Cook Islands',
		dial_code: '+682',
		code: 'CK',
		numberExample: '71234',
		dial: '682',
		codeLower: 'ck'
	},
	{
		name: 'Cameroon',
		dial_code: '+237',
		code: 'CM',
		numberExample: '671234567',
		dial: '237',
		codeLower: 'cm'
	},
	{
		name: 'China',
		dial_code: '+86',
		code: 'CN',
		numberExample: '13123456789',
		dial: '86',
		codeLower: 'cn'
	},
	{
		name: 'Costa Rica',
		dial_code: '+506',
		code: 'CR',
		numberExample: '83123456',
		dial: '506',
		codeLower: 'cr'
	},
	{
		name: 'Cuba',
		dial_code: '+53',
		code: 'CU',
		numberExample: '51234567',
		dial: '53',
		codeLower: 'cu'
	},
	{
		name: 'Cape Verde',
		dial_code: '+238',
		code: 'CV',
		numberExample: '9911234',
		dial: '238',
		codeLower: 'cv'
	},
	{
		name: 'Christmas Island',
		dial_code: '+61',
		code: 'CX',
		numberExample: '412345678',
		dial: '61',
		codeLower: 'cx'
	},
	{
		name: 'Cyprus',
		dial_code: '+357',
		code: 'CY',
		numberExample: '96123456',
		dial: '357',
		codeLower: 'cy'
	},
	{
		name: 'Czech Republic',
		dial_code: '+420',
		code: 'CZ',
		numberExample: '601123456',
		dial: '420',
		codeLower: 'cz'
	},
	{
		name: 'Germany',
		dial_code: '+49',
		code: 'DE',
		numberExample: '15123456789',
		dial: '49',
		codeLower: 'de'
	},
	{
		name: 'Djibouti',
		dial_code: '+253',
		code: 'DJ',
		numberExample: '77831001',
		dial: '253',
		codeLower: 'dj'
	},
	{
		name: 'Denmark',
		dial_code: '+45',
		code: 'DK',
		numberExample: '32123456',
		dial: '45',
		codeLower: 'dk'
	},
	{
		name: 'Dominica',
		dial_code: '+1767',
		code: 'DM',
		numberExample: '7672251234',
		dial: '1767',
		codeLower: 'dm'
	},
	{
		name: 'Dominican Republic',
		dial_code: '+1849',
		code: 'DO',
		numberExample: '8092345678',
		dial: '1849',
		codeLower: 'do'
	},
	{
		name: 'Algeria',
		dial_code: '+213',
		code: 'DZ',
		numberExample: '551234567',
		dial: '213',
		codeLower: 'dz'
	},
	{
		name: 'Estonia',
		dial_code: '+372',
		code: 'EE',
		numberExample: '51234567',
		dial: '372',
		codeLower: 'ee'
	},
	{
		name: 'Egypt',
		dial_code: '+20',
		code: 'EG',
		numberExample: '1001234567',
		dial: '20',
		codeLower: 'eg'
	},
	{
		name: 'Eritrea',
		dial_code: '+291',
		code: 'ER',
		numberExample: '7123456',
		dial: '291',
		codeLower: 'er'
	},
	{
		name: 'Spain',
		dial_code: '+34',
		code: 'ES',
		numberExample: '612345678',
		dial: '34',
		codeLower: 'es'
	},
	{
		name: 'Ethiopia',
		dial_code: '+251',
		code: 'ET',
		numberExample: '911234567',
		dial: '251',
		codeLower: 'et'
	},
	{
		name: 'Finland',
		dial_code: '+358',
		code: 'FI',
		numberExample: '412345678',
		dial: '358',
		codeLower: 'fi'
	},
	{
		name: 'Fiji',
		dial_code: '+679',
		code: 'FJ',
		numberExample: '7012345',
		dial: '679',
		codeLower: 'fj'
	},
	{
		name: 'Malvinas',
		dial_code: '+500',
		code: 'FK',
		numberExample: '51234',
		dial: '500',
		codeLower: 'fk'
	},
	{
		name: 'Micronesia',
		dial_code: '+691',
		code: 'FM',
		numberExample: '3501234',
		dial: '691',
		codeLower: 'fm'
	},
	{
		name: 'Faroe Islands',
		dial_code: '+298',
		code: 'FO',
		numberExample: '211234',
		dial: '298',
		codeLower: 'fo'
	},
	{
		name: 'France',
		dial_code: '+33',
		code: 'FR',
		numberExample: '612345678',
		dial: '33',
		codeLower: 'fr'
	},
	{
		name: 'Gabon',
		dial_code: '+241',
		code: 'GA',
		numberExample: '06031234',
		dial: '241',
		codeLower: 'ga'
	},
	{
		name: 'Grenada',
		dial_code: '+1473',
		code: 'GD',
		numberExample: '4734031234',
		dial: '1473',
		codeLower: 'gd'
	},
	{
		name: 'Georgia',
		dial_code: '+995',
		code: 'GE',
		numberExample: '555123456',
		dial: '995',
		codeLower: 'ge'
	},
	{
		name: 'French Guiana',
		dial_code: '+594',
		code: 'GF',
		numberExample: '694201234',
		dial: '594',
		codeLower: 'gf'
	},
	{
		name: 'Ghana',
		dial_code: '+233',
		code: 'GH',
		numberExample: '231234567',
		dial: '233',
		codeLower: 'gh'
	},
	{
		name: 'Gibraltar',
		dial_code: '+350',
		code: 'GI',
		numberExample: '57123456',
		dial: '350',
		codeLower: 'gi'
	},
	{
		name: 'Greenland',
		dial_code: '+299',
		code: 'GL',
		numberExample: '221234',
		dial: '299',
		codeLower: 'gl'
	},
	{
		name: 'Gambia',
		dial_code: '+220',
		code: 'GM',
		numberExample: '3012345',
		dial: '220',
		codeLower: 'gm'
	},
	{
		name: 'Guinea',
		dial_code: '+224',
		code: 'GN',
		numberExample: '601123456',
		dial: '224',
		codeLower: 'gn'
	},
	{
		name: 'Guadeloupe',
		dial_code: '+590',
		code: 'GP',
		numberExample: '690001234',
		dial: '590',
		codeLower: 'gp'
	},
	{
		name: 'Equatorial Guinea',
		dial_code: '+240',
		code: 'GQ',
		numberExample: '222123456',
		dial: '240',
		codeLower: 'gq'
	},
	{
		name: 'Greece',
		dial_code: '+30',
		code: 'GR',
		numberExample: '6912345678',
		dial: '30',
		codeLower: 'gr'
	},
	{
		name: 'Guatemala',
		dial_code: '+502',
		code: 'GT',
		numberExample: '51234567',
		dial: '502',
		codeLower: 'gt'
	},
	{
		name: 'Guam',
		dial_code: '+1671',
		code: 'GU',
		numberExample: '6713001234',
		dial: '1671',
		codeLower: 'gu'
	},
	{
		name: 'Guinea-Bissau',
		dial_code: '+245',
		code: 'GW',
		numberExample: '955012345',
		dial: '245',
		codeLower: 'gw'
	},
	{
		name: 'Guyana',
		dial_code: '+595',
		code: 'GY',
		numberExample: '6091234',
		dial: '595',
		codeLower: 'gy'
	},
	{
		name: 'Hong Kong',
		dial_code: '+852',
		code: 'HK',
		numberExample: '51234567',
		dial: '852',
		codeLower: 'hk'
	},
	{
		name: 'Honduras',
		dial_code: '+504',
		code: 'HN',
		numberExample: '91234567',
		dial: '504',
		codeLower: 'hn'
	},
	{
		name: 'Croatia',
		dial_code: '+385',
		code: 'HR',
		numberExample: '921234567',
		dial: '385',
		codeLower: 'hr'
	},
	{
		name: 'Haiti',
		dial_code: '+509',
		code: 'HT',
		numberExample: '34101234',
		dial: '509',
		codeLower: 'ht'
	},
	{
		name: 'Hungary',
		dial_code: '+36',
		code: 'HU',
		numberExample: '201234567',
		dial: '36',
		codeLower: 'hu'
	},
	{
		name: 'Indonesia',
		dial_code: '+62',
		code: 'ID',
		numberExample: '812345678',
		dial: '62',
		codeLower: 'id'
	},
	{
		name: 'Ireland',
		dial_code: '+353',
		code: 'IE',
		numberExample: '850123456',
		dial: '353',
		codeLower: 'ie'
	},
	{
		name: 'Israel',
		dial_code: '+972',
		code: 'IL',
		numberExample: '502345678',
		dial: '972',
		codeLower: 'il'
	},
	{
		name: 'India',
		dial_code: '+91',
		code: 'IN',
		numberExample: '8123456789',
		dial: '91',
		codeLower: 'in'
	},
	{
		name: 'British Indian',
		dial_code: '+246',
		code: 'IO',
		numberExample: '3801234',
		dial: '246',
		codeLower: 'io'
	},
	{
		name: 'Iraq',
		dial_code: '+964',
		code: 'IQ',
		numberExample: '7912345678',
		dial: '964',
		codeLower: 'iq'
	},
	{
		name: 'Iran',
		dial_code: '+98',
		code: 'IR',
		numberExample: '9123456789',
		dial: '98',
		codeLower: 'ir'
	},
	{
		name: 'Iceland',
		dial_code: '+354',
		code: 'IS',
		numberExample: '6111234',
		dial: '354',
		codeLower: 'is'
	},
	{
		name: 'Italy',
		dial_code: '+39',
		code: 'IT',
		numberExample: '3123456789',
		dial: '39',
		codeLower: 'it'
	},
	{
		name: 'Jamaica',
		dial_code: '+1876',
		code: 'JM',
		numberExample: '8762101234',
		dial: '1876',
		codeLower: 'jm'
	},
	{
		name: 'Jordan',
		dial_code: '+962',
		code: 'JO',
		numberExample: '790123456',
		dial: '962',
		codeLower: 'jo'
	},
	{
		name: 'Japan',
		dial_code: '+81',
		code: 'JP',
		numberExample: '9012345678',
		dial: '81',
		codeLower: 'jp'
	},
	{
		name: 'Kenya',
		dial_code: '+254',
		code: 'KE',
		numberExample: '712123456',
		dial: '254',
		codeLower: 'ke'
	},
	{
		name: 'Kyrgyzstan',
		dial_code: '+996',
		code: 'KG',
		numberExample: '700123456',
		dial: '996',
		codeLower: 'kg'
	},
	{
		name: 'Cambodia',
		dial_code: '+855',
		code: 'KH',
		numberExample: '91234567',
		dial: '855',
		codeLower: 'kh'
	},
	{
		name: 'Kiribati',
		dial_code: '+686',
		code: 'KI',
		numberExample: '72001234',
		dial: '686',
		codeLower: 'ki'
	},
	{
		name: 'Comoros',
		dial_code: '+269',
		code: 'KM',
		numberExample: '3212345',
		dial: '269',
		codeLower: 'km'
	},
	{
		name: 'Saint Kitts and Nevis',
		dial_code: '+1869',
		code: 'KN',
		numberExample: '8697652917',
		dial: '1869',
		codeLower: 'kn'
	},
	{
		name: 'North Korea',
		dial_code: '+850',
		code: 'KP',
		numberExample: '1921234567',
		dial: '850',
		codeLower: 'kp'
	},
	{
		name: 'South Korea',
		dial_code: '+82',
		code: 'KR',
		numberExample: '1000000000',
		dial: '82',
		codeLower: 'kr'
	},
	{
		name: 'Kuwait',
		dial_code: '+965',
		code: 'KW',
		numberExample: '50012345',
		dial: '965',
		codeLower: 'kw'
	},
	{
		name: 'Cayman Islands',
		dial_code: '+ 345',
		code: 'KY',
		numberExample: '3453231234',
		dial: ' 345',
		codeLower: 'ky'
	},
	{
		name: 'Kazakhstan',
		dial_code: '+77',
		code: 'KZ',
		numberExample: '7710009998',
		dial: '77',
		codeLower: 'kz'
	},
	{
		name: 'Laos',
		dial_code: '+856',
		code: 'LA',
		numberExample: '2023123456',
		dial: '856',
		codeLower: 'la'
	},
	{
		name: 'Lebanon',
		dial_code: '+961',
		code: 'LB',
		numberExample: '71123456',
		dial: '961',
		codeLower: 'lb'
	},
	{
		name: 'Saint Lucia',
		dial_code: '+1758',
		code: 'LC',
		numberExample: '7582845678',
		dial: '1758',
		codeLower: 'lc'
	},
	{
		name: 'Liechtenstein',
		dial_code: '+423',
		code: 'LI',
		numberExample: '660234567',
		dial: '423',
		codeLower: 'li'
	},
	{
		name: 'Sri Lanka',
		dial_code: '+94',
		code: 'LK',
		numberExample: '712345678',
		dial: '94',
		codeLower: 'lk'
	},
	{
		name: 'Liberia',
		dial_code: '+231',
		code: 'LR',
		numberExample: '770123456',
		dial: '231',
		codeLower: 'lr'
	},
	{
		name: 'Lesotho',
		dial_code: '+266',
		code: 'LS',
		numberExample: '50123456',
		dial: '266',
		codeLower: 'ls'
	},
	{
		name: 'Lithuania',
		dial_code: '+370',
		code: 'LT',
		numberExample: '61234567',
		dial: '370',
		codeLower: 'lt'
	},
	{
		name: 'Luxembourg',
		dial_code: '+352',
		code: 'LU',
		numberExample: '628123456',
		dial: '352',
		codeLower: 'lu'
	},
	{
		name: 'Latvia',
		dial_code: '+371',
		code: 'LV',
		numberExample: '21234567',
		dial: '371',
		codeLower: 'lv'
	},
	{
		name: 'Libyan Arab',
		dial_code: '+218',
		code: 'LY',
		numberExample: '912345678',
		dial: '218',
		codeLower: 'ly'
	},
	{
		name: 'Morocco',
		dial_code: '+212',
		code: 'MA',
		numberExample: '650123456',
		dial: '212',
		codeLower: 'ma'
	},
	{
		name: 'Monaco',
		dial_code: '+377',
		code: 'MC',
		numberExample: '612345678',
		dial: '377',
		codeLower: 'mc'
	},
	{
		name: 'Moldova',
		dial_code: '+373',
		code: 'MD',
		numberExample: '62112345',
		dial: '373',
		codeLower: 'md'
	},
	{
		name: 'Montenegro',
		dial_code: '+382',
		code: 'ME',
		numberExample: '67622901',
		dial: '382',
		codeLower: 'me'
	},
	{
		name: 'Madagascar',
		dial_code: '+261',
		code: 'MG',
		numberExample: '321234567',
		dial: '261',
		codeLower: 'mg'
	},
	{
		name: 'Marshall Islands',
		dial_code: '+692',
		code: 'MH',
		numberExample: '2351234',
		dial: '692',
		codeLower: 'mh'
	},
	{
		name: 'Macedonia',
		dial_code: '+389',
		code: 'MK',
		numberExample: '72345678',
		dial: '389',
		codeLower: 'mk'
	},
	{
		name: 'Mali',
		dial_code: '+223',
		code: 'ML',
		numberExample: '65012345',
		dial: '223',
		codeLower: 'ml'
	},
	{
		name: 'Myanmar',
		dial_code: '+95',
		code: 'MM',
		numberExample: '92123456',
		dial: '95',
		codeLower: 'mm'
	},
	{
		name: 'Mongolia',
		dial_code: '+976',
		code: 'MN',
		numberExample: '88123456',
		dial: '976',
		codeLower: 'mn'
	},
	{
		name: 'Macao',
		dial_code: '+853',
		code: 'MO',
		numberExample: '66123456',
		dial: '853',
		codeLower: 'mo'
	},
	{
		name: 'Mariana Islands',
		dial_code: '+1670',
		code: 'MP',
		numberExample: '6702345678',
		dial: '1670',
		codeLower: 'mp'
	},
	{
		name: 'Martinique',
		dial_code: '+596',
		code: 'MQ',
		numberExample: '696201234',
		dial: '596',
		codeLower: 'mq'
	},
	{
		name: 'Mauritania',
		dial_code: '+222',
		code: 'MR',
		numberExample: '22123456',
		dial: '222',
		codeLower: 'mr'
	},
	{
		name: 'Montserrat',
		dial_code: '+1664',
		code: 'MS',
		numberExample: '6644923456',
		dial: '1664',
		codeLower: 'ms'
	},
	{
		name: 'Malta',
		dial_code: '+356',
		code: 'MT',
		numberExample: '96961234',
		dial: '356',
		codeLower: 'mt'
	},
	{
		name: 'Mauritius',
		dial_code: '+230',
		code: 'MU',
		numberExample: '52512345',
		dial: '230',
		codeLower: 'mu'
	},
	{
		name: 'Maldives',
		dial_code: '+960',
		code: 'MV',
		numberExample: '7712345',
		dial: '960',
		codeLower: 'mv'
	},
	{
		name: 'Malawi',
		dial_code: '+265',
		code: 'MW',
		numberExample: '991234567',
		dial: '265',
		codeLower: 'mw'
	},
	{
		name: 'Mexico',
		dial_code: '+52',
		code: 'MX',
		numberExample: '12221234567',
		dial: '52',
		codeLower: 'mx'
	},
	{
		name: 'Malaysia',
		dial_code: '+60',
		code: 'MY',
		numberExample: '123456789',
		dial: '60',
		codeLower: 'my'
	},
	{
		name: 'Mozambique',
		dial_code: '+258',
		code: 'MZ',
		numberExample: '821234567',
		dial: '258',
		codeLower: 'mz'
	},
	{
		name: 'Namibia',
		dial_code: '+264',
		code: 'NA',
		numberExample: '811234567',
		dial: '264',
		codeLower: 'na'
	},
	{
		name: 'New Caledonia',
		dial_code: '+687',
		code: 'NC',
		numberExample: '751234',
		dial: '687',
		codeLower: 'nc'
	},
	{
		name: 'Niger',
		dial_code: '+227',
		code: 'NE',
		numberExample: '93123456',
		dial: '227',
		codeLower: 'ne'
	},
	{
		name: 'Norfolk Island',
		dial_code: '+672',
		code: 'NF',
		numberExample: '381234',
		dial: '672',
		codeLower: 'nf'
	},
	{
		name: 'Nigeria',
		dial_code: '+234',
		code: 'NG',
		numberExample: '8021234567',
		dial: '234',
		codeLower: 'ng'
	},
	{
		name: 'Nicaragua',
		dial_code: '+505',
		code: 'NI',
		numberExample: '81234567',
		dial: '505',
		codeLower: 'ni'
	},
	{
		name: 'Netherlands',
		dial_code: '+31',
		code: 'NL',
		numberExample: '612345678',
		dial: '31',
		codeLower: 'nl'
	},
	{
		name: 'Norway',
		dial_code: '+47',
		code: 'NO',
		numberExample: '40612345',
		dial: '47',
		codeLower: 'no'
	},
	{
		name: 'Nepal',
		dial_code: '+977',
		code: 'NP',
		numberExample: '9841234567',
		dial: '977',
		codeLower: 'np'
	},
	{
		name: 'Nauru',
		dial_code: '+674',
		code: 'NR',
		numberExample: '5551234',
		dial: '674',
		codeLower: 'nr'
	},
	{
		name: 'Niue',
		dial_code: '+683',
		code: 'NU',
		numberExample: '8884012',
		dial: '683',
		codeLower: 'nu'
	},
	{
		name: 'New Zealand',
		dial_code: '+64',
		code: 'NZ',
		numberExample: '211234567',
		dial: '64',
		codeLower: 'nz'
	},
	{
		name: 'Oman',
		dial_code: '+968',
		code: 'OM',
		numberExample: '92123456',
		dial: '968',
		codeLower: 'om'
	},
	{
		name: 'Panama',
		dial_code: '+507',
		code: 'PA',
		numberExample: '61234567',
		dial: '507',
		codeLower: 'pa'
	},
	{
		name: 'French Polynesia',
		dial_code: '+689',
		code: 'PF',
		numberExample: '87123456',
		dial: '689',
		codeLower: 'pf'
	},
	{
		name: 'Papua New Guinea',
		dial_code: '+675',
		code: 'PG',
		numberExample: '70123456',
		dial: '675',
		codeLower: 'pg'
	},
	{
		name: 'Philippines',
		dial_code: '+63',
		code: 'PH',
		numberExample: '9051234567',
		dial: '63',
		codeLower: 'ph'
	},
	{
		name: 'Pakistan',
		dial_code: '+92',
		code: 'PK',
		numberExample: '3012345678',
		dial: '92',
		codeLower: 'pk'
	},
	{
		name: 'Poland',
		dial_code: '+48',
		code: 'PL',
		numberExample: '512345678',
		dial: '48',
		codeLower: 'pl'
	},
	{
		name: 'Saint Pierre',
		dial_code: '+508',
		code: 'PM',
		numberExample: '551234',
		dial: '508',
		codeLower: 'pm'
	},
	{
		name: 'Puerto Rico',
		dial_code: '+1939',
		code: 'PR',
		numberExample: '7872345678',
		dial: '1939',
		codeLower: 'pr'
	},
	{
		name: 'Palestinian',
		dial_code: '+970',
		code: 'PS',
		numberExample: '599123456',
		dial: '970',
		codeLower: 'ps'
	},
	{
		name: 'Portugal',
		dial_code: '+351',
		code: 'PT',
		numberExample: '912345678',
		dial: '351',
		codeLower: 'pt'
	},
	{
		name: 'Palau',
		dial_code: '+680',
		code: 'PW',
		numberExample: '6201234',
		dial: '680',
		codeLower: 'pw'
	},
	{
		name: 'Paraguay',
		dial_code: '+595',
		code: 'PY',
		numberExample: '961456789',
		dial: '595',
		codeLower: 'py'
	},
	{
		name: 'Qatar',
		dial_code: '+974',
		code: 'QA',
		numberExample: '33123456',
		dial: '974',
		codeLower: 'qa'
	},
	{
		name: 'Reunion',
		dial_code: '+262',
		code: 'RE',
		numberExample: '692123456',
		dial: '262',
		codeLower: 're'
	},
	{
		name: 'Romania',
		dial_code: '+40',
		code: 'RO',
		numberExample: '712034567',
		dial: '40',
		codeLower: 'ro'
	},
	{
		name: 'Serbia',
		dial_code: '+381',
		code: 'RS',
		numberExample: '601234567',
		dial: '381',
		codeLower: 'rs'
	},
	{
		name: 'Russia',
		dial_code: '+7',
		code: 'RU',
		numberExample: '9123456789',
		dial: '7',
		codeLower: 'ru'
	},
	{
		name: 'Rwanda',
		dial_code: '+250',
		code: 'RW',
		numberExample: '720123456',
		dial: '250',
		codeLower: 'rw'
	},
	{
		name: 'Saudi Arabia',
		dial_code: '+966',
		code: 'SA',
		numberExample: '512345678',
		dial: '966',
		codeLower: 'sa'
	},
	{
		name: 'Solomon Islands',
		dial_code: '+677',
		code: 'SB',
		numberExample: '7421234',
		dial: '677',
		codeLower: 'sb'
	},
	{
		name: 'Seychelles',
		dial_code: '+248',
		code: 'SC',
		numberExample: '2510123',
		dial: '248',
		codeLower: 'sc'
	},
	{
		name: 'Sudan',
		dial_code: '+249',
		code: 'SD',
		numberExample: '911231234',
		dial: '249',
		codeLower: 'sd'
	},
	{
		name: 'Sweden',
		dial_code: '+46',
		code: 'SE',
		numberExample: '701234567',
		dial: '46',
		codeLower: 'se'
	},
	{
		name: 'Singapore',
		dial_code: '+65',
		code: 'SG',
		numberExample: '81234567',
		dial: '65',
		codeLower: 'sg'
	},
	{
		name: 'Saint Helena',
		dial_code: '+290',
		code: 'SH',
		numberExample: '51234',
		dial: '290',
		codeLower: 'sh'
	},
	{
		name: 'Slovenia',
		dial_code: '+386',
		code: 'SI',
		numberExample: '31234567',
		dial: '386',
		codeLower: 'si'
	},
	{
		name: 'Slovakia',
		dial_code: '+421',
		code: 'SK',
		numberExample: '912123456',
		dial: '421',
		codeLower: 'sk'
	},
	{
		name: 'Sierra Leone',
		dial_code: '+232',
		code: 'SL',
		numberExample: '25123456',
		dial: '232',
		codeLower: 'sl'
	},
	{
		name: 'San Marino',
		dial_code: '+378',
		code: 'SM',
		numberExample: '66661212',
		dial: '378',
		codeLower: 'sm'
	},
	{
		name: 'Senegal',
		dial_code: '+221',
		code: 'SN',
		numberExample: '701234567',
		dial: '221',
		codeLower: 'sn'
	},
	{
		name: 'Somalia',
		dial_code: '+252',
		code: 'SO',
		numberExample: '71123456',
		dial: '252',
		codeLower: 'so'
	},
	{
		name: 'Suriname',
		dial_code: '+597',
		code: 'SR',
		numberExample: '7412345',
		dial: '597',
		codeLower: 'sr'
	},
	{
		name: 'Sao Tome and Principe',
		dial_code: '+239',
		code: 'ST',
		numberExample: '9812345',
		dial: '239',
		codeLower: 'st'
	},
	{
		name: 'El Salvador',
		dial_code: '+503',
		code: 'SV',
		numberExample: '70123456',
		dial: '503',
		codeLower: 'sv'
	},
	{
		name: 'Syrian Arab',
		dial_code: '+963',
		code: 'SY',
		numberExample: '944567890',
		dial: '963',
		codeLower: 'sy'
	},
	{
		name: 'Swaziland',
		dial_code: '+268',
		code: 'SZ',
		numberExample: '76123456',
		dial: '268',
		codeLower: 'sz'
	},
	{
		name: 'Turks and Caicos',
		dial_code: '+1649',
		code: 'TC',
		numberExample: '6492311234',
		dial: '1649',
		codeLower: 'tc'
	},
	{
		name: 'Chad',
		dial_code: '+235',
		code: 'TD',
		numberExample: '63012345',
		dial: '235',
		codeLower: 'td'
	},
	{
		name: 'Togo',
		dial_code: '+228',
		code: 'TG',
		numberExample: '90112345',
		dial: '228',
		codeLower: 'tg'
	},
	{
		name: 'Thailand',
		dial_code: '+66',
		code: 'TH',
		numberExample: '812345678',
		dial: '66',
		codeLower: 'th'
	},
	{
		name: 'Tajikistan',
		dial_code: '+992',
		code: 'TJ',
		numberExample: '917123456',
		dial: '992',
		codeLower: 'tj'
	},
	{
		name: 'Tokelau',
		dial_code: '+690',
		code: 'TK',
		numberExample: '7290',
		dial: '690',
		codeLower: 'tk'
	},
	{
		name: 'Timor-Leste',
		dial_code: '+670',
		code: 'TL',
		numberExample: '77212345',
		dial: '670',
		codeLower: 'tl'
	},
	{
		name: 'Turkmenistan',
		dial_code: '+993',
		code: 'TM',
		numberExample: '66123456',
		dial: '993',
		codeLower: 'tm'
	},
	{
		name: 'Tunisia',
		dial_code: '+216',
		code: 'TN',
		numberExample: '20123456',
		dial: '216',
		codeLower: 'tn'
	},
	{
		name: 'Tonga',
		dial_code: '+676',
		code: 'TO',
		numberExample: '7715123',
		dial: '676',
		codeLower: 'to'
	},
	{
		name: 'Turkey',
		dial_code: '+90',
		code: 'TR',
		numberExample: '5012345678',
		dial: '90',
		codeLower: 'tr'
	},
	{
		name: 'Trinidad and Tobago',
		dial_code: '+1868',
		code: 'TT',
		numberExample: '8682911234',
		dial: '1868',
		codeLower: 'tt'
	},
	{
		name: 'Tuvalu',
		dial_code: '+688',
		code: 'TV',
		numberExample: '901234',
		dial: '688',
		codeLower: 'tv'
	},
	{
		name: 'Taiwan',
		dial_code: '+886',
		code: 'TW',
		numberExample: '912345678',
		dial: '886',
		codeLower: 'tw'
	},
	{
		name: 'Tanzania',
		dial_code: '+255',
		code: 'TZ',
		numberExample: '621234567',
		dial: '255',
		codeLower: 'tz'
	},
	{
		name: 'Ukraine',
		dial_code: '+380',
		code: 'UA',
		numberExample: '501234567',
		dial: '380',
		codeLower: 'ua'
	},
	{
		name: 'Uganda',
		dial_code: '+256',
		code: 'UG',
		numberExample: '712345678',
		dial: '256',
		codeLower: 'ug'
	},
	{
		name: 'United States',
		dial_code: '+1',
		code: 'US',
		numberExample: '2015550123',
		dial: '1',
		codeLower: 'us'
	},
	{
		name: 'Uruguay',
		dial_code: '+598',
		code: 'UY',
		numberExample: '94231234',
		dial: '598',
		codeLower: 'uy'
	},
	{
		name: 'Uzbekistan',
		dial_code: '+998',
		code: 'UZ',
		numberExample: '912345678',
		dial: '998',
		codeLower: 'uz'
	},
	{
		name: 'Vatican City',
		dial_code: '+379',
		code: 'VA',
		numberExample: '3123456789',
		dial: '379',
		codeLower: 'va'
	},
	{
		name: 'Saint Vincent',
		dial_code: '+1784',
		code: 'VC',
		numberExample: '7844301234',
		dial: '1784',
		codeLower: 'vc'
	},
	{
		name: 'Virgin Islands',
		dial_code: '+1284',
		code: 'VG',
		numberExample: '2843001234',
		dial: '1284',
		codeLower: 'vg'
	},
	{
		name: 'Virgin Islands',
		dial_code: '+1340',
		code: 'VI',
		numberExample: '3406421234',
		dial: '1340',
		codeLower: 'vi'
	},
	{
		name: 'Vietnam',
		dial_code: '+84',
		code: 'VN',
		numberExample: '912345678',
		dial: '84',
		codeLower: 'vn'
	},
	{
		name: 'Vanuatu',
		dial_code: '+678',
		code: 'VU',
		numberExample: '5912345',
		dial: '678',
		codeLower: 'vu'
	},
	{
		name: 'Wallis and Futuna',
		dial_code: '+681',
		code: 'WF',
		numberExample: '501234',
		dial: '681',
		codeLower: 'wf'
	},
	{
		name: 'Samoa',
		dial_code: '+685',
		code: 'WS',
		numberExample: '7212345',
		dial: '685',
		codeLower: 'ws'
	},
	{
		name: 'Yemen',
		dial_code: '+967',
		code: 'YE',
		numberExample: '712345678',
		dial: '967',
		codeLower: 'ye'
	},
	{
		name: 'Mayotte',
		dial_code: '+262',
		code: 'YT',
		numberExample: '639012345',
		dial: '262',
		codeLower: 'yt'
	},
	{
		name: 'South Africa',
		dial_code: '+27',
		code: 'ZA',
		numberExample: '711234567',
		dial: '27',
		codeLower: 'za'
	},
	{
		name: 'Zambia',
		dial_code: '+260',
		code: 'ZM',
		numberExample: '955123456',
		dial: '260',
		codeLower: 'zm'
	},
	{
		name: 'Zimbabwe',
		dial_code: '+263',
		code: 'ZW',
		numberExample: '712345678',
		dial: '263',
		codeLower: 'zw'
	}
];

<div class="navbar__options">
	<app-navbar-searcher
		class="searcher__container w-100"
		(clickSuggestionOutput)="clickSuggestion($event)"
	></app-navbar-searcher>
	<div class="container__buttons__links">
		<ng-container *ngFor="let item of listMenu">
			<ng-container *ngIf="item.url === ''">
				<button
					(click)="openSubMenu(item.name)"
					[class.item__active]="activeSubmenu === item.name"
					class="button__item"
				>
					{{ item.name }}
					<img
						[src]="
							activeSubmenu !== item.name
								? '../../../../../../assets/icons/arrow-down-mobile.svg'
								: '../../../../../../assets/icons/arrow-up-mobile.svg'
						"
						alt="arrow-down-mobile"
					/>
				</button>
				<div
					[ngClass]="activeSubmenu !== item.name ? 'hidden__container' : getShowContainerClass(item.name)"
					[style.height]="activeSubmenu === item.name ? item.submenu.length * 42 + 10 + 'px' : '0'"
					class="w-100"
				>
					<ng-container *ngFor="let subItem of item.submenu">
						<button (click)="navigatePage(subItem.url)" class="button__item button__subitem">{{ subItem.name }}</button>
					</ng-container>
					<hr />
				</div>
			</ng-container>
			<ng-container *ngIf="item.url !== ''">
				<button (click)="navigatePage(item.url)" class="button__item">{{ item.name }}</button>
			</ng-container>
		</ng-container>
	</div>
	<button class="button__plan__your__visit" (click)="navigatePage('/planea-tu-visita')">Planea tu visita</button>
</div>

export const dataFooter = [
	{
		id: 1,
		name: 'Conoce más',
		links: [
			{
				id: 1,
				name: 'Manual contra el bullying',
				url: '#'
			},
			{
				id: 2,
				name: 'Portal de Proveedores',
				url: '#'
			},
			{
				id: 3,
				name: 'Portal de Locatarios',
				url: '#'
			},
			{
				id: 4,
				name: 'Relación con inversionistas',
				url: '/relacion-con-inversionistas'
			},
			{
				id: 5,
				name: 'Términos y Condiciones Personal Shopper',
				url: '/terminos-y-condiciones-personal-shopper'
			},
			{
				id: 6,
				name: 'Uso de Silla de Ruedas y Coches para bebés',
				url: '/uso-silla-ruedas-y-coches-para-bebes'
			},
			{
				id: 7,
				name: 'Política Pet Friendly',
				url: '/politica-pet-friendly'
			},
			{
				id: 8,
				name: 'Política de Uso Lockers',
				url: '/politica-uso-lockers'
			},
			{
				id: 9,
				name: 'Libro de reclamaciones',
				url: '/libro-de-reclamaciones/ok'
			}
		]
	},
	{
		id: 2,
		name: 'Explora',
		links: [
			{
				id: 1,
				name: 'Tiendas',
				url: '/tiendas'
			},
			{
				id: 2,
				name: 'Gastronomía',
				url: '/gastronomia'
			},
			{
				id: 3,
				name: 'Centro Financiero',
				url: '/centros-finacieros'
			},
			{
				id: 4,
				name: 'Descuentos J',
				url: '/descuentos'
			},
			{
				id: 5,
				name: 'Eventos',
				url: '/eventos'
			}
		]
	},
	{
		id: 3,
		name: 'Otros servicios',
		links: [
			{
				id: 1,
				name: 'Canal J',
				url: '#'
			},
			{
				id: 2,
				name: 'Revista J',
				url: '#'
			},
			{
				id: 3,
				name: 'Trabaja con nosotros',
				url: '#'
			},
			{
				id: 4,
				name: 'Abre tu tienda con nosotros',
				url: '/salesforce'
			},
			{
				id: 5,
				name: 'Formato de solicitud de Derechos ARCO',
				url: '/formulario-arco'
			}
		]
	}
];

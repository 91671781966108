import { Component, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
	@Input() numberPages!: number;
	@Input() pagesDisplayed!: number;
	getScreenWidth = window.innerWidth;
	@Output() clickButtonPage = new EventEmitter<number>();
	totalPages = [{ pNumber: '1', pageFunction: () => this.changePage(1), isNumber: true, pLabel: '01' }];
	currentPage = 1;
	disabledRightArrow = true;
	disabledLeftArrow = true;
	@Input() listType?: string = '';

	validate(isNumber: boolean, pNumber: string): boolean {
		return isNumber && parseInt(pNumber) === this.currentPage;
	}

	getPagesToShow(numberPreviousPages: number, numberLaterPages: number, page: number, numberPages: number) {
		let pages: Array<{ pNumber: string; pageFunction: () => void; isNumber: boolean; pLabel: string }> = [];
		pages = [...pages, { pNumber: `1`, pageFunction: () => this.changePage(1), isNumber: true, pLabel: '01' }];
		for (let p = numberPreviousPages; p >= 1; p--) {
			if (p === numberPreviousPages && page - (numberPreviousPages + 1) > 1) {
				pages = [
					...pages,
					{ pNumber: '...', pageFunction: () => this.changePage(page - 1), isNumber: false, pLabel: '...' }
				];
			} else {
				const pageToAdd = page - p;
				pages = [
					...pages,
					{
						pNumber: `${page - p}`,
						pageFunction: () => this.changePage(page - p),
						isNumber: true,
						pLabel: pageToAdd >= 10 ? `${pageToAdd}` : `0${pageToAdd}`
					}
				];
			}
		}
		if (page !== 1 && page !== numberPages)
			pages = [
				...pages,
				{
					pNumber: `${page}`,
					pageFunction: () => this.changePage(page),
					isNumber: true,
					pLabel: page >= 10 ? `${page}` : `0${page}`
				}
			];
		for (let p = 1; p <= numberLaterPages; p++) {
			if (p === numberLaterPages && page + numberLaterPages + 1 < numberPages) {
				pages = [
					...pages,
					{ pNumber: '...', pageFunction: () => this.changePage(page + 1), isNumber: false, pLabel: '...' }
				];
			} else {
				const pageToAdd = page + p;
				pages = [
					...pages,
					{
						pNumber: `${page + p}`,
						pageFunction: () => this.changePage(page + p),
						isNumber: true,
						pLabel: pageToAdd >= 10 ? `${pageToAdd}` : `0${pageToAdd}`
					}
				];
			}
		}
		pages = [
			...pages,
			{
				pNumber: `${numberPages}`,
				pageFunction: () => this.changePage(numberPages),
				isNumber: true,
				pLabel: `${numberPages >= 10 ? `${numberPages}` : `0${numberPages}`}`
			}
		];
		return pages;
	}

	createPages(page: number, numberPages = this.numberPages) {
		let pages: Array<{ pNumber: string; pageFunction: () => void; isNumber: boolean; pLabel: string }> = [];
		if (numberPages <= this.pagesDisplayed) {
			for (let p = 1; p <= numberPages; p++) {
				pages = [
					...pages,
					{
						pNumber: `${p}`,
						pageFunction: () => this.changePage(p),
						isNumber: true,
						pLabel: p >= 10 ? `${p}` : `0${p}`
					}
				];
			}
		} else {
			const usablePages = this.pagesDisplayed - 3;
			if (usablePages / 2 >= page - 1) {
				const previousPages = page - 2;
				const laterPages = usablePages - (previousPages > 0 ? previousPages : page - 2);
				pages = this.getPagesToShow(previousPages, laterPages, page, numberPages);
			} else if (usablePages / 2 >= numberPages - page) {
				const laterPages = numberPages - (page + 1);
				const previousPages = usablePages - (laterPages < numberPages - 1 ? laterPages : 0);
				pages = this.getPagesToShow(previousPages, laterPages, page, numberPages);
			} else {
				const pageCut = usablePages / 2;
				pages = this.getPagesToShow(pageCut, pageCut, page, numberPages);
			}
		}
		this.totalPages = pages;
		this.currentPage = page;
		this.disabledLeftArrow = page === 1;
		this.disabledRightArrow = page === numberPages;
	}

	changePage(page: number, numberPages = this.numberPages) {
		this.createPages(page, numberPages);
		this.clickButtonPage.emit(page);
	}

	// eslint-disable-next-line @angular-eslint/use-lifecycle-interface
	ngOnChanges(changes: SimpleChanges) {
		if (changes['numberPages']) {
			const newTotalPages = changes['numberPages'].currentValue;
			this.createPages(1, newTotalPages);
		}
		if (changes['listType']) this.currentPage = 1;
	}
}

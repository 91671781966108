import { Component, OnInit, HostListener } from '@angular/core';
import { dataMenuMyAccount } from './my-account-data';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/api/auth.service';
import { BannerService } from 'src/app/core/services/api/banner-api.service';
import { LocalResolverService } from 'src/app/core/services/api/local-resolver.service';
import { FacebookService } from 'ngx-facebook';

interface IMenuItem {
	id: number;
	name: string;
	url: string;
	icon: string;
}

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
	listMenu: IMenuItem[] = [];

	ngOnInit(): void {
		this.listMenu = [...dataMenuMyAccount];
	}

	constructor(
		private _router: Router,
		private _authService: AuthService,
		private _bannerService: BannerService,
		private _localService: LocalResolverService,
		private fb: FacebookService
	) {}

	showAlert = false;
	messageError = 'Parece que hubo un error al cerrar sesion. Por favor, inténtalo más tarde.';

	changePage(url: string) {
		this._router.navigateByUrl(url);
		this.openMenu();
	}

	isActivePage(url: string) {
		return this._router.url === url;
	}

	getScreenWidth = window.innerWidth;
	showOptions = false;

	openMenu() {
		this.showOptions = !this.showOptions;
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.getScreenWidth = window.innerWidth;
		this.showOptions = false;
	}

	getActivePage() {
		const pageName = this.listMenu.find((item) => item.url === this._router.url)?.name;
		return pageName;
	}

	closeSesion() {
		this._authService.signOut().subscribe({
			next: () => {
				this._bannerService.hiddenWidgetSocial = false;
				this._router.navigateByUrl('/');
				this._localService.deleteDataLocal('ccj');
				this._localService.deleteDataLocal('lfb');
				this.fb.getLoginStatus().then((res) => {
					if (res.status === 'connected') {
						this.fb.logout().then(() => console.log('Logged out!'));
					}
				});
				this._authService.deleteCookieFB();
			},
			error: () => {
				this.showAlert = true;
				window.scroll({ top: 0, behavior: 'smooth' });
			}
		});
	}

	closeAlert() {
		this.showAlert = false;
	}
}

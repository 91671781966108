import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { NavBarOptionsModule } from '../navbar-options/navbar-options.module';
import { DropdownMenuModule } from '../dropdown-menu/dropdown-menu.module';
import { ButtonLinkModule } from '../button-link/button-link.module';
import { ButtonModule } from '../button/button.module';
import { NavBarSearchModule } from '../navbar-searcher/navbar-searcher.module';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		NavBarOptionsModule,
		DropdownMenuModule,
		ButtonLinkModule,
		ButtonModule,
		NavBarSearchModule
	],
	exports: [NavbarComponent],
	declarations: [NavbarComponent],
	providers: []
})
export class NavBarModule {}

<label *ngIf="label !== ''">{{ label }}</label>
<div class="btn-group" [class.selected]="isSelected" [class.error]="isError && !default" [class.isGroup]="isGroup">
	<a class="drop" id="dropdownMenuButton" (click)="clickDropdown()">
		<span>{{ title }}</span>
		<img src="assets/icons/arrow-down-dark.svg" alt="v" />
	</a>
	<div class="dropdown-menu-container" *ngIf="showItems" [class.fullWidth]="fullWidth">
		<div class="dropdown-input" *ngIf="isSearch">
			<input
				type="text"
				class="dropdown-menu-container__search"
				placeholder="Buscar Rubro..."
				[formControl]="valueSearch"
			/>
		</div>
		<a class="dropdown-item" *ngFor="let item of listFilter" (click)="clickItemSelect(item)">
			{{ item.name }}
		</a>
	</div>
</div>
<span class="span__error" *ngIf="isError && !default">{{ label }} es obligatorio</span>

<div class="group__input">
	<input
		type="text"
		[placeholder]="placeholder"
		[class]="class + ' icon'"
		autocomplete="off"
		(keyup.enter)="seeAllResults(word)"
		[formControl]="valueSearch"
	/>
	<img src="assets/icons/search-dark.svg" alt="o" />

	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		*ngIf="valueSearch.value"
		(click)="clickClear()"
	>
		<path
			d="M6.66699 13.3327L13.3337 6.66602"
			stroke="#A3A3A3"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13.3337 13.3327L6.66699 6.66602"
			stroke="#A3A3A3"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>

	<div class="group__input__resultBox" *ngIf="listSuggestions.length > 0">
		<ul>
			<li *ngFor="let item of listSuggestions" (click)="clickSuggestion(item.nombre, item)">
				<span [innerHTML]="item.wordHtml"></span>
			</li>
			<li><span class="all" (click)="seeAllResults(word)">Ver todo</span></li>
		</ul>
	</div>
</div>

<div class="modal__notification__container">
	<div class="header__modal">
		<h3>Notificaciones</h3>
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			(click)="closeOptions()"
		>
			<path d="M16 32L32 16" stroke="#171717" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M32 32L16 16" stroke="#171717" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
		</svg>
	</div>
	<ng-container *ngIf="!isLoading">
		<div class="container__detail__info">
			<div class="section__detail" *ngIf="listNewNotifications.length > 0">
				<div class="title">Nuevas</div>
				<ng-container *ngFor="let notification of listNewNotifications">
					<div class="card__notification">
						<div class="title">{{ notification.encabezado }}</div>
						<div class="description">{{ notification.texto }}</div>
					</div>
				</ng-container>
			</div>
			<div class="section__detail" *ngIf="listPreviousNotifications.length > 0">
				<div class="title">Anteriores</div>
				<ng-container *ngFor="let notification of listPreviousNotifications">
					<div class="card__notification">
						<div class="title">{{ notification.encabezado }}</div>
						<div class="description">{{ notification.texto }}</div>
					</div>
				</ng-container>
			</div>
		</div>
		<app-button
			[text]="'Ver todas mis notificaciones'"
			[color]="'primary'"
			[size]="'lg'"
			[classes]="'w-100'"
			class="btn__action"
			(click)="navigatePage()"
		></app-button>
	</ng-container>
	<ng-container *ngIf="isLoading">
		<div class="col-12 justify-center">
			<app-loader></app-loader>
		</div>
	</ng-container>
</div>

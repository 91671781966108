import { NgModule } from '@angular/core';
import { PaginationComponent } from './pagination.component';
import { CommonModule } from '@angular/common';
@NgModule({
	imports: [CommonModule],
	exports: [PaginationComponent],
	declarations: [PaginationComponent],
	providers: []
})
export class PaginationModule {}

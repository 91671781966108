<ng-container *ngIf="iconName === 'user'">
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.9987 9.99984C12.2999 9.99984 14.1654 8.13436 14.1654 5.83317C14.1654 3.53198 12.2999 1.6665 9.9987 1.6665C7.69751 1.6665 5.83203 3.53198 5.83203 5.83317C5.83203 8.13436 7.69751 9.99984 9.9987 9.99984Z"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17.1565 18.3333C17.1565 15.1083 13.9482 12.5 9.99817 12.5C6.04817 12.5 2.83984 15.1083 2.83984 18.3333"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</ng-container>
<ng-container *ngIf="iconName === 'heart'">
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10.518 17.3418C10.2346 17.4418 9.76797 17.4418 9.48464 17.3418C7.06797 16.5168 1.66797 13.0752 1.66797 7.24183C1.66797 4.66683 3.74297 2.5835 6.3013 2.5835C7.81797 2.5835 9.15964 3.31683 10.0013 4.45016C10.843 3.31683 12.193 2.5835 13.7013 2.5835C16.2596 2.5835 18.3346 4.66683 18.3346 7.24183C18.3346 13.0752 12.9346 16.5168 10.518 17.3418Z"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</ng-container>
<ng-container *ngIf="iconName === 'star'">
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.4421 2.9252L12.9087 5.85853C13.1087 6.26686 13.6421 6.65853 14.0921 6.73353L16.7504 7.1752C18.4504 7.45853 18.8504 8.69186 17.6254 9.90853L15.5587 11.9752C15.2087 12.3252 15.0171 13.0002 15.1254 13.4835L15.7171 16.0419C16.1837 18.0669 15.1087 18.8502 13.3171 17.7919L10.8254 16.3169C10.3754 16.0502 9.63375 16.0502 9.17541 16.3169L6.68375 17.7919C4.90041 18.8502 3.81708 18.0585 4.28375 16.0419L4.87541 13.4835C4.98375 13.0002 4.79208 12.3252 4.44208 11.9752L2.37541 9.90853C1.15875 8.69186 1.55041 7.45853 3.25041 7.1752L5.90875 6.73353C6.35041 6.65853 6.88375 6.26686 7.08375 5.85853L8.55041 2.9252C9.35041 1.33353 10.6504 1.33353 11.4421 2.9252Z"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</ng-container>
<ng-container *ngIf="iconName === 'calendar'">
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.66797 1.6665V4.1665"
			stroke-width="1.5"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13.332 1.6665V4.1665"
			stroke-width="1.5"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M2.91797 7.5752H17.0846"
			stroke-width="1.5"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17.5 7.08317V14.1665C17.5 16.6665 16.25 18.3332 13.3333 18.3332H6.66667C3.75 18.3332 2.5 16.6665 2.5 14.1665V7.08317C2.5 4.58317 3.75 2.9165 6.66667 2.9165H13.3333C16.25 2.9165 17.5 4.58317 17.5 7.08317Z"
			stroke-width="1.5"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path d="M9.99803 11.4167H10.0055" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M6.91209 11.4167H6.91957" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M6.91209 13.9167H6.91957" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</ng-container>
<ng-container *ngIf="iconName === 'bell'">
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10.0175 2.4248C7.25914 2.4248 5.01747 4.66647 5.01747 7.4248V9.83314C5.01747 10.3415 4.80081 11.1165 4.54247 11.5498L3.58414 13.1415C2.99247 14.1248 3.40081 15.2165 4.48414 15.5831C8.07581 16.7831 11.9508 16.7831 15.5425 15.5831C16.5508 15.2498 16.9925 14.0581 16.4425 13.1415L15.4841 11.5498C15.2341 11.1165 15.0175 10.3415 15.0175 9.83314V7.4248C15.0175 4.6748 12.7675 2.4248 10.0175 2.4248Z"
			stroke-width="1.5"
			stroke-miterlimit="10"
			stroke-linecap="round"
		/>
		<path
			d="M11.5599 2.6667C11.3016 2.5917 11.0349 2.53337 10.7599 2.50003C9.9599 2.40003 9.19323 2.45837 8.47656 2.6667C8.71823 2.05003 9.31823 1.6167 10.0182 1.6167C10.7182 1.6167 11.3182 2.05003 11.5599 2.6667Z"
			stroke-width="1.5"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12.5156 15.8833C12.5156 17.2583 11.3906 18.3833 10.0156 18.3833C9.33229 18.3833 8.69896 18.1 8.24896 17.65C7.79896 17.2 7.51562 16.5666 7.51562 15.8833"
			stroke-width="1.5"
			stroke-miterlimit="10"
		/>
	</svg>
</ng-container>
<ng-container *ngIf="iconName === 'setting'">
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
			stroke-width="1.5"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M1.66797 10.7334V9.2667C1.66797 8.40003 2.3763 7.68336 3.2513 7.68336C4.75964 7.68336 5.3763 6.6167 4.61797 5.30836C4.18464 4.55836 4.44297 3.58336 5.2013 3.15003L6.64297 2.32503C7.3013 1.93336 8.1513 2.1667 8.54297 2.82503L8.63464 2.98336C9.38464 4.2917 10.618 4.2917 11.3763 2.98336L11.468 2.82503C11.8596 2.1667 12.7096 1.93336 13.368 2.32503L14.8096 3.15003C15.568 3.58336 15.8263 4.55836 15.393 5.30836C14.6346 6.6167 15.2513 7.68336 16.7596 7.68336C17.6263 7.68336 18.343 8.3917 18.343 9.2667V10.7334C18.343 11.6 17.6346 12.3167 16.7596 12.3167C15.2513 12.3167 14.6346 13.3834 15.393 14.6917C15.8263 15.45 15.568 16.4167 14.8096 16.85L13.368 17.675C12.7096 18.0667 11.8596 17.8334 11.468 17.175L11.3763 17.0167C10.6263 15.7084 9.39297 15.7084 8.63464 17.0167L8.54297 17.175C8.1513 17.8334 7.3013 18.0667 6.64297 17.675L5.2013 16.85C4.44297 16.4167 4.18464 15.4417 4.61797 14.6917C5.3763 13.3834 4.75964 12.3167 3.2513 12.3167C2.3763 12.3167 1.66797 11.6 1.66797 10.7334Z"
			stroke-width="1.5"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</ng-container>

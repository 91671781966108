<div class="group__input w-100">
	<input
		type="text"
		[placeholder]="placeholder"
		[class]="'icon'"
		autocomplete="off"
		[formControl]="valueSearch"
		#generalSearchInput
	/>
	<img src="assets/icons/search-dark.svg" alt="o" />
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="clickClose()">
		<path
			d="M6.66667 13.3334L13.3333 6.66669"
			stroke="#171717"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13.3333 13.3334L6.66667 6.66669"
			stroke="#171717"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
	<div class="group__input__resultBox" *ngIf="listSuggestions.length > 0">
		<ul>
			<li *ngFor="let item of listSuggestions" (click)="clickSuggestion(item.url)">
				<span [innerHTML]="item.wordHtml"></span>
			</li>
		</ul>
	</div>
</div>

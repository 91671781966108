const months = {
	'01': 'enero',
	'02': 'febrero',
	'03': 'marzo',
	'04': 'abril',
	'05': 'mayo',
	'06': 'junio',
	'07': 'julio',
	'08': 'agosto',
	'09': 'septiembre',
	'10': 'octubre',
	'11': 'noviembre',
	'12': 'diciembre'
};

interface IMonths {
	'01': string;
	'02': string;
	'03': string;
	'04': string;
	'05': string;
	'06': string;
	'07': string;
	'08': string;
	'09': string;
	'10': string;
	'11': string;
	'12': string;
}

export function formatDate(startDate: string, endDate: string) {
	const startDatePart = startDate.split('-');
	const endDatePart = endDate.split('-');
	let response = '';
	if (startDatePart[1] === endDatePart[1]) {
		const month = startDatePart[1];
		response = `Del ${startDatePart[2]} al ${endDatePart[2]} de ${months[month as keyof IMonths]}`;
	} else {
		response = `Del ${startDatePart[2]} de ${months[startDatePart[1] as keyof IMonths]} al ${endDatePart[2]} de ${
			months[endDatePart[1] as keyof IMonths]
		}`;
	}
	return response;
}

export function padTo2Digits(num: number) {
	return num.toString().padStart(2, '0');
}

export function formatDateTime(date: Date) {
	return (
		[date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-') +
		' ' +
		[padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(':')
	);
}

export function formatBirthdate(date: string) {
	const dateParts = date.split('-');
	return date !== '' ? `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}` : '';
}

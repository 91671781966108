/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URL_BACKEND } from '../../config/url';
import { AboutJockey, Nosotros } from '../../interfaces/about.interface';

@Injectable({ providedIn: 'root' })
export class AboutService {
	constructor(private _httpClient: HttpClient) {}
	dataAbout!: AboutJockey;
	dataLinks!: Nosotros;
	dataTerms!: string;
	dataPolitics!: string;

	getAboutJockey(): Observable<AboutJockey> {
		const url = `${URL_BACKEND}/organization/information/get`;
		return this._httpClient.get<AboutJockey>(url);
	}
}

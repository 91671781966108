/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { URL_BACKEND } from '../../config/url';
import { CookieService } from 'ngx-cookie-service';

import {
	IRequestAuth,
	IRequestLogin,
	IResponseAuth,
	IResquestRegister,
	IRequestUser,
	IUserResponseEdit,
	IChangePassword,
	IUserInfoResponse,
	IDataToken,
	Interest,
	IResquestFacebook,
	IResetPassword
} from '../../interfaces/auth.interface';
import { LocalResolverService } from './local-resolver.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
	constructor(
		private _httpClient: HttpClient,
		private _localService: LocalResolverService,
		private cookieService: CookieService
	) {}
	initAuth = {
		CorreoElectronico: '',
		Contrasenia: '',
		TerminosCondiciones: false
	};

	authTemporal: IRequestAuth = this.initAuth;
	isProfileError = false;

	loginWithEmail(data: IRequestLogin): Observable<IResponseAuth> {
		const url = `${URL_BACKEND}/user/customer/login`;
		data.PermanecerConectado = true;
		return this._httpClient.post<IResponseAuth>(url, { ...data });
	}

	loginWithFacebook(data: IResquestFacebook): Observable<IResponseAuth> {
		const url = `${URL_BACKEND}/user/customer/login`;
		return this._httpClient.post<IResponseAuth>(url, data);
	}

	registerWithEmail(data: IResquestRegister): Observable<IResponseAuth> {
		const url = `${URL_BACKEND}/user/customer/register`;
		return this._httpClient.post<IResponseAuth>(url, data);
	}

	registerWithFacebook(data: IResquestRegister, email: string): Observable<IResponseAuth> {
		const url = `${URL_BACKEND}/user/customer/register`;
		data.CorreoElectronico = email;
		data.TerminosCondiciones = true;
		data.Identificacion = null;
		data.Contrasenia = '';
		return this._httpClient.post<IResponseAuth>(url, { ...data });
	}

	validEmailByRegister(email: string): Observable<boolean> {
		const url = `${URL_BACKEND}/user/customer/validarEmail?email=${email}`;
		return this._httpClient.get<boolean>(url);
	}

	getInfo(): Observable<IUserInfoResponse> {
		const idUser = this.getIdUser();
		const url = `${URL_BACKEND}/user/customer/show?id=${idUser}`;
		return this._httpClient.get<IUserInfoResponse>(url);
	}

	isLogged() {
		const info = this._localService.getDataLocal('ccj');
		return info ? true : null;
	}

	isLoggedFb() {
		const info = this._localService.getDataLocal('lfb');
		return info ? true : null;
	}

	getIdUser() {
		const info = this._localService.getDataLocal('ccj');
		return info ? info.id : null;
	}

	getEmailUser() {
		const info = this._localService.getDataLocal('ccj');
		return info ? info.email : null;
	}

	signOut(): Observable<any> {
		const url = `${URL_BACKEND}/user/customer/logout`;
		return this._httpClient.get<any>(url);
	}

	deleteCredentials() {
		this._localService.deleteDataLocal('scc');
	}

	getCredentials() {
		const info = this._localService.getDataLocal('scc');
		console.log(info, 'recordarme');
		return info ? info : null;
	}

	setCredentials(data: IRequestLogin) {
		this._localService.setNewLocal('scc', data);
	}

	setCookies() {
		this._localService.setNewLocal('acc', 'ok');
	}

	getCookies() {
		return this._localService.getDataLocal('acc');
	}

	setUserLogin(data: IDataToken) {
		this._localService.setNewLocal('ccj', data);
	}

	cleanAuthTemporal() {
		this.authTemporal = this.initAuth;
	}

	uploadImage(formData: any): Observable<any> {
		const url = `${URL_BACKEND}/document/upload/image`;
		return this._httpClient.post<any>(url, formData);
	}

	editProfileUser(data: IRequestUser): Observable<IUserResponseEdit> {
		const fullCel = data.paisCelular + '.' + data.numeroCelular;
		data.numeroCelular = fullCel;
		const url = `${URL_BACKEND}/user/customer`;
		return this._httpClient.put<IUserResponseEdit>(url, data);
	}

	recoverPassword(email: string): Observable<any> {
		const params = new HttpParams().set('email', email);
		const url = `${URL_BACKEND}/user/customer/recoverPassword?`;
		return this._httpClient.get<any>(url, { params: params });
	}

	changePassword(data: IChangePassword): Observable<any> {
		const params = new HttpParams()
			.set('email', data.email)
			.set('previousPassword', data.password)
			.set('newPassword', data.newPassword);

		const url = `${URL_BACKEND}/user/customer/changePassword?`;
		return this._httpClient.get<any>(url, { params: params });
	}

	resetPassword(data: IResetPassword): Observable<any> {
		const params = new HttpParams()
			.set('newPassword', data.newPassword)
			.set('confirmPassword', data.confirmPassword)
			.set('token', data.token);

		const url = `${URL_BACKEND}/user/customer/changePasswordToken?`;
		return this._httpClient.get<any>(url, { params: params });
	}

	getListInterest(): Observable<Interest[]> {
		const url = `${URL_BACKEND}/user/interest`;
		return this._httpClient.get<Interest[]>(url).pipe(
			tap((res) =>
				res.map((item) => {
					item.selected = false;
					return item;
				})
			)
		);
	}

	deleteCookieFB() {
		this.cookieService.delete('fbm_' + environment.clientIdFacebook);
		this.cookieService.delete('fbsr_' + environment.clientIdFacebook);
	}
	/**Auth Facebook **/
	generateToken(token: string) {
		const url = `https://graph.facebook.com/oauth/access_token?grant_type=fb_exchange_token&
		client_id=${environment.clientIdFacebook}&
		client_secret=${environment.clientSecretFacebook}&
		fb_exchange_token=${token}`;
		return this._httpClient.get<any>(url);
	}

	getFacebookData(token: string) {
		const url = `https://graph.facebook.com/me?fields=first_name,last_name,email,id&access_token=${token}`;
		return this._httpClient.get<any>(url);
	}

	confirmEmailRegister(token: string) {
		const params = new HttpParams().set('token', token);
		const url = `${URL_BACKEND}/user/customer/confirmEmail?`;
		return this._httpClient.get<any>(url, { params: params });
	}

	resendLinkConfirmEmail(email: string) {
		const params = new HttpParams().set('email', email);
		const url = `${URL_BACKEND}/user/customer/sendConfirmEmail?`;
		return this._httpClient.get<any>(url, { params: params });
	}
}

<div class="container__modal__confirmation" #modalContentRef>
	<div class="modal__info">
		<div class="modal__header">
			<div class="icon">
				<img [src]="icon" />
			</div>
			<span class="title">{{ title }}</span>
			<span class="subtitle" *ngIf="subtitle !== ''"> {{ subtitle }}</span>
		</div>
		<div class="row">
			<div class="col-12">
				<app-button
					[text]="textButton"
					[color]="'primary'"
					[size]="'lg'"
					[classes]="'w-100'"
					(click)="clickButtonEvent()"
					[random]="true"
				></app-button>
			</div>
		</div>
	</div>
</div>

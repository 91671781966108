<div
	*ngIf="!bannerService.hiddenWidgetSocial"
	class="container_social_networks"
	[ngClass]="stopSocialNetworks ? 'absolute__container' : 'fixed__container'"
	[style.bottom]="stopSocialNetworks ? bottomAbsolutePosition + 'px' : '30px'"
>
	<div class="container__icon__social__network" (click)="navigateUrl(_aboutService.dataLinks.urlFacebook)">
		<div class="icon__social__network" [style.background-image]="'url(/assets/icons/facebook-white.svg)'"></div>
	</div>
	<div class="container__icon__social__network" (click)="navigateUrl(_aboutService.dataLinks.urlInstagram)">
		<div class="icon__social__network" [style.background-image]="'url(/assets/icons/instagram-white.svg)'"></div>
	</div>
	<div class="container__icon__social__network" (click)="navigateUrl(_aboutService.dataLinks.urlYoutube)">
		<div class="icon__social__network" [style.background-image]="'url(/assets/icons/youtube-white.svg)'"></div>
	</div>
	<div class="container__icon__social__network" (click)="navigateUrl(_aboutService.dataLinks.tikTok)">
		<div class="icon__social__network" [style.background-image]="'url(/assets/icons/tiktok-white.svg)'"></div>
	</div>
	<div
		class="container__icon__social__network"
		(click)="navigateUrl('https://wa.me/' + _aboutService.dataLinks.whatsApp)"
	>
		<div class="icon__social__network" [style.background-image]="'url(/assets/icons/whatsapp-white.svg)'"></div>
	</div>
</div>

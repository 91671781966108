<div *ngIf="getScreenWidth > 1199" class="container__form" [ngClass]="classAuth">
	<article
		[ngStyle]="{ transform: classAuth !== '' ? 'translate3d(' + positionXForm + 'px,0px,0px)' : 'none' }"
		id="modalContentRef"
	>
		<app-login
			*ngIf="isLogin"
			(clickButton)="changeModalDisplayed($event)"
			(clickClose)="closeModal()"
			(showAlertError)="showAlertLogin($event)"
			(clickForgetPassword)="showSectionPassword($event)"
		></app-login>
		<app-register
			*ngIf="!isLogin"
			(clickButton)="changeModalDisplayed($event)"
			(clickClose)="closeModal()"
			(showAlertError)="showAlertRegister($event)"
			(hideAlertError)="closeAlert()"
			[isChecked]="isAccpetTerms"
			[showSectionForget]="showSectionForget"
			(clickHideForget)="showSectionPassword($event)"
		></app-register>
	</article>
	<section
		class="container__form__imagen"
		[ngStyle]="{ transform: classAuth !== '' ? 'translate3d(' + positionXImage + 'px,0px,0px)' : 'none' }"
		[class.isRegister]="!isLogin"
		[style]="{ backgroundImage: 'url(' + imageSesion + ')' }"
	>
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			(click)="closeModal()"
		>
			<path d="M16 32L32 16" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M32 32L16 16" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
		</svg>
	</section>
</div>

<div *ngIf="getScreenWidth < 1200" class="container__form__responsive">
	<article id="modalContentRefMob">
		<app-login
			[@fadeInOut]
			*ngIf="isLogin"
			(clickButton)="changeModalDisplayed($event)"
			(clickClose)="closeModal()"
			(showAlertError)="showAlertLogin($event)"
			(clickForgetPassword)="showSectionPassword($event)"
		></app-login>
		<app-register
			[@fadeInOut]
			*ngIf="!isLogin"
			(clickButton)="changeModalDisplayed($event)"
			(clickClose)="closeModal()"
			(showAlertError)="showAlertRegister($event)"
			(hideAlertError)="closeAlert()"
			[isChecked]="isAccpetTerms"
			[showSectionForget]="showSectionForget"
			(clickHideForget)="showSectionPassword($event)"
		></app-register>
	</article>
</div>
<app-alerta
	*ngIf="showAlert"
	[title]="isLogin ? 'Error de inicio de sesión' : isRecoveryPass ? 'Parece que hubo un error' : 'Error de registro'"
	[icon]="'warning-2.svg'"
	[messages]="[messageError]"
	(close)="closeAlert()"
	[closeTime]="true"
	[time]="6000"
></app-alerta>

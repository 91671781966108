<div class="container__login">
	<div class="container__login__close">
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			(click)="closeModal()"
		>
			<path
				d="M1 11L11 1M1 1L11 11"
				stroke="#171717"
				stroke-width="1.67"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	</div>
	<div class="container__login__title">
		<app-title [text]="'Bienvenido de nuevo'" [line]="true"></app-title>
	</div>
	<form [formGroup]="formGroup" autocomplete="true" class="container__login__form">
		<app-input
			[label]="'Correo electrónico'"
			[placeholder]="'Correo electrónico'"
			[icon]="'sms.svg'"
			[type]="'email'"
			[name]="'CorreoElectronico'"
			[class]="emailField.touched && emailField!.invalid ? 'error' : ''"
			[maxlength]="50"
			[labelError]="
				emailField.touched && (emailField!.hasError('required') || emailField!.hasError('pattern'))
					? 'Correo inválido.'
					: ''
			"
		></app-input>
		<app-input
			[label]="'Contraseña'"
			[placeholder]="'Contraseña'"
			[password]="true"
			[maxlength]="50"
			[name]="'Contrasenia'"
			[class]="passwordField.touched && passwordField!.hasError('required') ? 'error' : ''"
		></app-input>
		<div class="container__login__remember">
			<app-input-check [label]="'Recordarme'" [name]="'PermanecerConectado'"></app-input-check>
			<span class="link" (click)="forgetPassword()">Olvidé mi contraseña</span>
		</div>

		<div class="container__login__buttons">
			<app-button
				[text]="'Iniciar sesión'"
				[color]="'primary'"
				[size]="'lg'"
				[random]="true"
				[classes]="'w-100'"
				(click)="loginWithEmail()"
				[disabled]="isProcess"
			></app-button>
			<!--<span>- o -</span>
			<app-button
				[text]="getScreenWidth > 291 ? 'Iniciar sesión con Facebook' : 'Continuar con Facebook'"
				[color]="'facebook'"
				[size]="'lg'"
				[srcImg]="'button_facebook.svg'"
				[disabled]="isProcessFacebook"
				[left]="true"
				[classes]="'w-100'"
				(click)="signInWithFB()"
			>
			</app-button>-->
		</div>
		<div class="container__login__link">
			<span class="text-account">¿No tienes una cuenta? </span>
			<span (click)="showRegisterSection()" class="link">Regístrate</span>
		</div>
	</form>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputSearchComponent } from './input-search.component';

@NgModule({
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	exports: [InputSearchComponent],
	declarations: [InputSearchComponent],
	providers: []
})
export class InputSearchModule {}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map, Subject, takeUntil, tap } from 'rxjs';

interface Item {
	value: any;
	name: string;
}
@Component({
	selector: 'app-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit, OnDestroy {
	@Input() title = 'Seleccionar';
	@Input() labelError = '';
	@Input() label = '';
	@Input() listItems: Item[] = [];
	@Input() isError = false;
	@Input() default!: number | null;
	@Input() isGroup = false;
	@Input() fullWidth = false;
	@Input() isSearch = false;
	@Output() clickItem = new EventEmitter<number>();
	showItems = false;
	isSelected = false;
	listFilter: Item[] = [];
	valueSearch = new FormControl('');
	private destroy$ = new Subject<unknown>();

	ngOnInit(): void {
		if (this.default !== undefined && this.default !== null) {
			this.isSelected = true;
			this.title = this.listItems[this.default].name;
		}
		this.listFilter = this.listItems;
		if (this.isSearch) {
			this.onChangeSearch();
		}
	}
	clickDropdown() {
		this.showItems = !this.showItems;
		this.valueSearch.setValue('');
		if (!this.showItems) {
			this.isError = this.isSelected ? false : true;
		}
	}

	clickItemSelect(item: Item) {
		this.isSelected = true;
		this.showItems = false;
		this.title = item.name;
		this.isError = false;
		this.valueSearch.setValue('');
		this.clickItem.emit(item.value);
	}

	setValid() {
		if (!this.isSelected) {
			this.isError = true;
		}
	}

	private onChangeSearch(): void {
		this.valueSearch.valueChanges
			.pipe(
				map((search) => search?.toLowerCase().trim()),
				map((search) => {
					if (search == undefined || search?.length <= 1) {
						this.listFilter = this.listItems;
					}
					return search;
				}),
				debounceTime(500),
				distinctUntilChanged(),
				filter((search) => search !== '' && search !== undefined && search?.length > 1),
				tap((search) => {
					if (search !== undefined) {
						this.listFilter = this.listItems.filter((item) => item.name.toLowerCase().includes(search));
					}
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.destroy$.next({});
		this.destroy$.complete();
	}
}

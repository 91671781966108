<div class="container__icon__component">
	<div class="container__icon" (click)="openNotifications()">
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			*ngIf="newNotifications"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M12.2727 2.13781C11.5795 1.84009 10.817 1.67505 10.0176 1.67505C6.84504 1.67505 4.26759 4.2525 4.26759 7.42505V9.83338C4.26759 9.99718 4.22985 10.247 4.15429 10.5192C4.07902 10.7904 3.98282 11.0239 3.89912 11.1648L3.89838 11.166L2.94162 12.755C2.53975 13.4229 2.45271 14.1788 2.7028 14.8542C2.95256 15.5287 3.50973 16.0453 4.24381 16.2938L4.24659 16.2947C7.99213 17.5461 12.0335 17.5463 15.7791 16.2951C17.2457 15.8095 17.8813 14.0818 17.0857 12.7558L16.1308 11.1699C16.0486 11.0257 15.9544 10.7911 15.8805 10.5215C15.8054 10.2477 15.7676 9.99741 15.7676 9.83338V8.49132C15.6795 8.49712 15.5906 8.50006 15.501 8.50006C15.0706 8.50006 14.6561 8.43209 14.2676 8.30629V9.83338C14.2676 10.1777 14.3381 10.569 14.4339 10.9182C14.5299 11.2682 14.6674 11.6353 14.8345 11.9249L15.7997 13.5279C16.1036 14.0349 15.8564 14.6897 15.3072 14.8713L15.3049 14.872C11.868 16.0203 8.1604 16.0205 4.7234 14.8725C4.37498 14.7542 4.18669 14.5419 4.10946 14.3334C4.03247 14.1254 4.0371 13.8438 4.2269 13.5284L5.18512 11.9369L5.1868 11.9341C5.36126 11.6415 5.50221 11.2714 5.59964 10.9204C5.697 10.5697 5.76759 10.1779 5.76759 9.83338V7.42505C5.76759 5.08093 7.67347 3.17505 10.0176 3.17505C10.5868 3.17505 11.1309 3.28872 11.6284 3.49445C11.7569 2.99832 11.9782 2.53956 12.2727 2.13781ZM13.5707 2.91124C13.2329 3.3212 13.0231 3.84064 13.0026 4.40833C13.6026 5.00207 14.0281 5.77177 14.1925 6.6307C14.5731 6.86495 15.0213 7.00006 15.501 7.00006C15.5854 7.00006 15.6688 6.99588 15.751 6.98771C15.6258 5.33995 14.7991 3.88123 13.5707 2.91124Z"
				fill="#A3A3A3"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M7.77829 2.39304C8.1294 1.4971 9.0011 0.866699 10.0182 0.866699C11.0354 0.866699 11.9071 1.4971 12.2582 2.39304C12.3625 2.65912 12.3065 2.96132 12.1138 3.17237C11.9211 3.38342 11.6252 3.46664 11.3508 3.38696C11.1251 3.32142 10.8984 3.27231 10.6697 3.24458L10.6669 3.24424C9.96247 3.15619 9.29904 3.20866 8.68593 3.38689C8.41147 3.46667 8.11552 3.38352 7.92278 3.17247C7.73003 2.96142 7.674 2.65916 7.77829 2.39304Z"
				fill="#A3A3A3"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8.26562 15.8833C8.26562 16.3592 8.46376 16.8041 8.77929 17.1196C9.09481 17.4352 9.53977 17.6333 10.0156 17.6333C10.9764 17.6333 11.7656 16.8441 11.7656 15.8833H13.2656C13.2656 17.6725 11.8048 19.1333 10.0156 19.1333C9.12481 19.1333 8.3031 18.7648 7.71863 18.1803C7.13415 17.5958 6.76562 16.7741 6.76562 15.8833H8.26562Z"
				fill="#A3A3A3"
			/>
			<path
				d="M15 11.75C17.6234 11.75 19.75 9.62335 19.75 7C19.75 4.37665 17.6234 2.25 15 2.25C12.3766 2.25 10.25 4.37665 10.25 7C10.25 9.62335 12.3766 11.75 15 11.75Z"
				fill="#EB0045"
				stroke="white"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			*ngIf="!newNotifications"
		>
			<path
				d="M10.0175 2.4248C7.25914 2.4248 5.01747 4.66647 5.01747 7.4248V9.83314C5.01747 10.3415 4.80081 11.1165 4.54247 11.5498L3.58414 13.1415C2.99247 14.1248 3.40081 15.2165 4.48414 15.5831C8.07581 16.7831 11.9508 16.7831 15.5425 15.5831C16.5508 15.2498 16.9925 14.0581 16.4425 13.1415L15.4841 11.5498C15.2341 11.1165 15.0175 10.3415 15.0175 9.83314V7.4248C15.0175 4.6748 12.7675 2.4248 10.0175 2.4248Z"
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
			/>
			<path
				d="M11.5599 2.6667C11.3016 2.5917 11.0349 2.53337 10.7599 2.50003C9.9599 2.40003 9.19323 2.45837 8.47656 2.6667C8.71823 2.05003 9.31823 1.6167 10.0182 1.6167C10.7182 1.6167 11.3182 2.05003 11.5599 2.6667Z"
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12.5156 15.8833C12.5156 17.2583 11.3906 18.3833 10.0156 18.3833C9.33229 18.3833 8.69896 18.1 8.24896 17.65C7.79896 17.2 7.51562 16.5666 7.51562 15.8833"
				stroke-width="1.5"
				stroke-miterlimit="10"
			/>
		</svg>
	</div>
	<app-modal-notification-detail
		[hidden]="!isOpen"
		(closeItems)="openNotifications($event)"
		class="notifications__items"
		(newItems)="newNotifications = $event"
	></app-modal-notification-detail>
</div>

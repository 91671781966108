<span *ngIf="label" class="span__label" [class.span__label__stroke]="labelStroke" [class.disabled]="disabled"
	>{{ label }} <span *ngIf="isRequired" class="span__label__required">*</span></span
>
<div class="group-input" *ngIf="password === false">
	<input
		*ngIf="!isOnlyText"
		[type]="type"
		[placeholder]="placeholder"
		[value]="value"
		[class]="class"
		[class.label]="label"
		[class.icon]="icon"
		[name]="name"
		[formControlName]="name"
		(focus)="cleanErrorEmail()"
		[readonly]="disabled"
		role="presentation"
		autocomplete="off"
		[maxlength]="maxlength"
	/>

	<input
		*ngIf="isOnlyText"
		appOnlyLetters
		[type]="type"
		[placeholder]="placeholder"
		[value]="value"
		[class]="class"
		[class.label]="label"
		[class.icon]="icon"
		[name]="name"
		[formControlName]="name"
		(focus)="cleanErrorEmail()"
		[readonly]="disabled"
		role="presentation"
		autocomplete="off"
		[maxlength]="maxlength"
	/>

	<img [src]="'assets/icons/' + icon" *ngIf="icon" />
	<img [src]="'assets/icons/' + icon2" class="icon-right" *ngIf="icon2" />
</div>

<div class="group-input" *ngIf="password">
	<input
		[type]="isShowPassword ? 'text' : 'password'"
		[placeholder]="placeholder"
		[class]="class"
		[value]="value"
		[class.label]="label"
		class="icon"
		(focus)="cleanErrorEmail()"
		[formControlName]="name"
		role="presentation"
		autocomplete="off"
		[maxlength]="maxlength"
	/>
	<img src="assets/icons/candado.svg" />
	<img
		[src]="isShowPassword ? 'assets/icons/eye-slash.svg' : 'assets/icons/eye.svg'"
		class="icon-right"
		(click)="showPassword()"
	/>
</div>

<span class="span__error" *ngIf="labelError !== ''" [class.isGroup]="isGroup">{{ labelError }}</span>

<div *ngIf="close" class="alert" [class]="type" role="alert" [class.isPage]="isPage">
	<div class="alert__icon">
		<b><img [src]="'/assets/icons/' + icon" /></b>
	</div>
	<div class="alert__message">
		<b
			><span>{{ title }}</span></b
		>
		<ng-container *ngFor="let item of messages">
			<span class="message" *ngIf="item !== ''">{{ item }}</span>
		</ng-container>
	</div>
	<div class="alert__close">
		<i class="bi bi-x-lg" (click)="onClose($event)"></i>
	</div>
</div>

import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SesionComponent } from '../../layouts/main-layout/components/modal/sesion/sesion.component';
import { AuthInterceptorService } from 'src/app/core/interceptors/authorization.interceptor';

@Component({
	selector: 'app-modal-message',
	templateUrl: './modal-message.component.html',
	styleUrls: ['./modal-message.component.scss']
})
export class ModalMessageComponent implements AfterViewInit, OnInit, OnDestroy {
	@Input() icon = 'assets/icons/email-alert.svg';
	@Input() title = 'Title';
	@Input() type = 'success';
	@Input() subtitle = '';
	@Input() textButton = 'Aceptar';
	@Input() isOpenLogin = false;
	@Output() clickButton = new EventEmitter();
	@ViewChild('modalContentRef')
	modalContentRef!: ElementRef;

	constructor(
		public activeModal: NgbActiveModal,
		private _modalService: NgbModal,
		private _interceptor: AuthInterceptorService
	) {}

	ngOnInit(): void {
		document.body.classList.add('show__scroll');
	}

	ngAfterViewInit(): void {
		this.modalContentRef.nativeElement.scrollTo(0, 0);
	}

	clickButtonEvent() {
		this.activeModal.close();
		if (!this.isOpenLogin) {
			this.clickButton.emit();
		} else {
			this.clickButton.emit();
			this._interceptor.isOpenModal = false;
			this._modalService.open(SesionComponent, {
				scrollable: true,
				centered: true,
				backdrop: 'static',
				windowClass: 'modal-auth'
			});
		}
	}

	ngOnDestroy(): void {
		document.body.classList.remove('show__scroll');
	}
}

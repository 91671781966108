/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EncryptStorage } from 'storage-encryption';

@Injectable({ providedIn: 'root' })
export class LocalResolverService {
	encryptStorage = new EncryptStorage(environment.keyLocalStorage);

	constructor() {}
	getDataLocalHttp(key: string) {
		const newKey = this.crypt(environment.keyLocalStorage, key);
		return this.encryptStorage.decrypt(newKey) || null;
	}

	setNewHttp(key: string, value: any) {
		const newKey = this.crypt(environment.keyLocalStorage, key);
		this.encryptStorage.encrypt(newKey, value);
	}

	setNewLocal(key: string, value: any) {
		this.encryptStorage.encrypt(key, value);
	}

	getDataLocal(key: string) {
		return this.encryptStorage.decrypt(key);
	}

	deleteDataLocal(key: string) {
		this.encryptStorage.remove(key);
	}

	crypt(salt: string, text: string) {
		const textToChars = (text: string) => text.split('').map((c) => c.charCodeAt(0));
		const byteHex = (n: any) => ('0' + Number(n).toString(16)).substr(-2);
		const applySaltToChar = (code: any) => textToChars(salt).reduce((a, b) => a ^ b, code);
		return text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
	}

	decrypt(salt: string, encoded: string) {
		const textToChars = (text: any) => text.split('').map((c: string) => c.charCodeAt(0));
		const applySaltToChar = (code: any) => textToChars(salt).reduce((a: any, b: any) => a ^ b, code);
		const match = encoded.match(/.{1,2}/g);
		if (match) {
			return match
				.map((hex) => parseInt(hex, 16))
				.map(applySaltToChar)
				.map((charCode) => String.fromCharCode(charCode))
				.join('');
		}
		return null;
	}
}

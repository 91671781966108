/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, Injectable, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import {
	ControlContainer,
	FormGroupDirective,
	FormGroup,
	FormBuilder,
	Validators,
	AbstractControl
} from '@angular/forms';
import {
	NgbCalendar,
	NgbDateAdapter,
	NgbDateParserFormatter,
	NgbDateStruct,
	NgbDatepickerI18n
} from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

const I18N_VALUES: any = {
	es: {
		weekdays: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'],
		months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
		weekLabel: 'sem'
	}
	// other languages you would support
};

@Injectable()
export class I18n {
	language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
	constructor(private _i18n: I18n) {
		super();
	}

	getWeekdayLabel(weekday: number): string {
		return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
	}
	override getWeekLabel(): string {
		return I18N_VALUES[this._i18n.language].weekLabel;
	}
	getMonthShortName(month: number): string {
		return I18N_VALUES[this._i18n.language].months[month - 1];
	}
	getMonthFullName(month: number): string {
		return this.getMonthShortName(month);
	}
	getDayAriaLabel(date: NgbDateStruct): string {
		return `${date.day}-${date.month}-${date.year}`;
	}
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
	readonly DELIMITER = '/';

	parse(value: string): NgbDateStruct | null {
		if (value) {
			const date = value.split(this.DELIMITER);
			return {
				day: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				year: parseInt(date[2], 10)
			};
		}
		return null;
	}

	format(date: NgbDateStruct | null): string {
		return date
			? (date.day > 9 ? date.day : `0${date.day}`) +
					this.DELIMITER +
					(date.month > 9 ? date.month : `0${date.month}`) +
					this.DELIMITER +
					date.year
			: '';
	}
}

@Component({
	selector: 'app-input-date',
	templateUrl: './input-date.component.html',
	styleUrls: ['./input-date.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
	providers: [
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
		I18n,
		{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
	]
})
export class InputDateComponent implements OnInit, OnDestroy {
	@Input() placeholder?: string;
	@Input() label?: string;
	@Input() dateDefault?: string = '';
	@Input() name?: any = 'input';
	@Input() disabled = false;
	@Input() isRequired = false;
	@Input() inputClass = '';
	@Output() outFocus = new EventEmitter<any>();
	currentDate: NgbDateStruct;
	form!: FormGroup;

	constructor(
		private ngbCalendar: NgbCalendar,
		private dateAdapter: NgbDateAdapter<string>,
		private formBuilder: FormBuilder
	) {
		this.currentDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
	}

	private destroy$ = new Subject<unknown>();

	formatDateToShow(date: any) {
		if (date) {
			const dateParts = date.split('/');
			return {
				day: Number(dateParts[0]),
				month: Number(dateParts[1]),
				year: Number(dateParts[2])
			};
		} else {
			return null;
		}
	}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			valueDate: [this.formatDateToShow(this.dateDefault), [Validators.required, this.validateDate]]
		});
	}

	validateDate(control: any) {
		const date = control.value;
		if (!date?.day || !date?.month || !date?.year) return { isInvalidDate: true };
		return null;
	}

	formatDateToSend(date: any) {
		return `${date?.year}-${date?.month > 9 ? date?.month : `0${date?.month}`}-${
			date?.day > 9 ? date?.day : `0${date?.day}`
		}`;
	}

	getDateSelected() {
		this.form.markAllAsTouched();
		return {
			date: this.dateField!.value ? this.formatDateToSend(this.dateField!.value) : null,
			isValid: this.dateField!.hasError('required') || this.dateField!.hasError('isInvalidDate') ? false : true
		};
	}

	onFocusOutEvent() {
		const data = this.getDateSelected();
		this.outFocus.emit(data);
	}

	resetForm() {
		this.form.reset();
		this.form.controls['valueDate'].clearValidators();
		this.form.controls['valueDate'].addValidators([Validators.required, this.validateDate]);
	}

	setValue(date: string) {
		this.dateField.setValue(this.formatDateToShow(date));
	}

	ngOnDestroy(): void {
		this.destroy$.next({});
		this.destroy$.complete();
	}

	get dateField(): AbstractControl {
		return this.form.get('valueDate')!;
	}

	get today() {
		return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
	}
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { INotification } from 'src/app/core/interfaces/notirfication.interface';
import { NotificationService } from 'src/app/core/services/api/notification-api.service';

@Component({
	selector: 'app-modal-notification-detail',
	templateUrl: './modal-notification-detail.component.html',
	styleUrls: ['./modal-notification-detail.component.scss']
})
export class ModalNotificationDetailComponent implements OnInit {
	@Output() closeItems = new EventEmitter<boolean>();
	@Output() newItems = new EventEmitter<boolean>();
	listNewNotifications: INotification[] = [];
	listPreviousNotifications: INotification[] = [];
	page = 1;
	limit = 5;
	totalPages = 0;
	isLoading = false;

	constructor(public _notificationService: NotificationService, private _router: Router) {}

	ngOnInit(): void {
		this.getNotifications();
	}

	getNotifications() {
		this.isLoading = true;
		this.listNewNotifications = [];
		this.listPreviousNotifications = [];
		this._notificationService.getListNotifications(this.page, this.limit).subscribe({
			next: (res) => {
				console.log('res.notificaciones', res.notificaciones);
				res.notificaciones.forEach((notification) => {
					if (notification.visto) {
						this.listPreviousNotifications = [...this.listPreviousNotifications, notification];
					} else {
						this.listNewNotifications = [...this.listNewNotifications, notification];
					}
				});
				if (this.listNewNotifications.length > 0) this.newItems.emit(true);
				this.isLoading = false;
			},
			error: (e) => {
				console.log(e);
				this.isLoading = false;
			}
		});
	}

	closeOptions() {
		this.closeItems.emit(false);
	}

	navigatePage() {
		this.closeOptions();
		this._router.navigateByUrl('mi-cuenta/notificaciones');
	}
}

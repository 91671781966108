/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { dataMenu, serviciosUrl } from './data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SesionComponent } from '../../layouts/main-layout/components/modal/sesion/sesion.component';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { NavbarOptionsComponent } from '../navbar-options/navbar-options.component';
import { BannerService } from 'src/app/core/services/api/banner-api.service';
import { AuthService } from 'src/app/core/services/api/auth.service';
import { CampaignService } from 'src/app/core/services/api/campaign-api.service';
import { JockeyServices } from 'src/app/core/services/api/service-api.service';
import { IService } from 'src/app/core/interfaces/service.interface';
import { encodeUrl } from 'src/app/core/functions/encodeUrl';
@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
	listMenu: any[] = [];
	isDark = false;
	isHover = false;
	isStaticDark!: boolean;
	showNavbarOptions = false;
	getScreenWidth = 0;
	activeSubmenu = 0;
	hoverSearch = false;
	hoverUser = false;
	isOpenSearch = false;
	isInit = true;
	//stateMenu = "inactive";

	constructor(
		private _modalService: NgbModal,
		private router: Router,
		private _bannerService: BannerService,
		private _authService: AuthService,
		private _campaignService: CampaignService,
		private _jockeyService: JockeyServices
	) {
		this.getStaticStyle();
		this.showNavbarOptions = false;
		this.isDark = this._bannerService.isErrorPage
			? false
			: this.isStaticDark
			? this.isStaticDark
			: !this._bannerService.isBannerActive;
		this._bannerService.bannerChangeState.subscribe((value) => {
			this.isDark = this._bannerService.isErrorPage ? false : this.isStaticDark ? this.isStaticDark : !value;
		});
	}

	getStaticStyle() {
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: any) => {
			this.isStaticDark =
				event.url == '/tiendas' ||
				event.url.includes('/eventos') ||
				event.url.includes('/descuentos') ||
				event.url == '/centros-finacieros' ||
				event.url == '/' ||
				event.url == '/gastronomia' ||
				event.url.includes('/campana') ||
				event.url == '/sostenibilidad' ||
				event.url == '/artJockey'
					? false
					: true;
		});
	}

	ngOnInit(): void {
		this.getMenuItems();
		this.getScreenWidth = window.innerWidth;
	}

	getMenuItems() {
		this.listMenu = JSON.parse(JSON.stringify(dataMenu));
		this.getMenuItemsServices();
		//this.getMenuItemsCampaigns();
	}

	sortByName(a: any, b: any) {
		const nameA = a.name.toUpperCase();
		const nameB = b.name.toUpperCase();
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}
		return 0;
	}

	getMenuItemsServices() {
		const servicesItemsMenu = JSON.parse(JSON.stringify(serviciosUrl));
		this._jockeyService.getServicesByCategory('adicional').subscribe({
			next: (res: IService[]) => {
				const activeMenuItems = servicesItemsMenu.filter((service: any) =>
					res.some((item: IService) => item.nombre === service.name)
				);
				activeMenuItems.forEach((service: any) => {
					this.listMenu[2].submenu.push(service);
				});
				const otherItems = res.filter(
					(service: IService) =>
						!servicesItemsMenu.some((item: any) => item.name === service.nombre || service.nombre === 'Parking')
				);
				otherItems.forEach((otherService: IService, index: number) => {
					const serviceItem = {
						id: index + 9,
						name: otherService.nombre,
						url: `/servicio-adicional/${encodeUrl(otherService.nombre)}`
					};
					this.listMenu[2].submenu.push(serviceItem);
				});
				this.listMenu[2].submenu.push(
					{
						id: 7,
						name: 'Parking',
						url: '/parking'
					},
					{
						id: 8,
						name: 'Otros servicios',
						url: '/otros-servicios'
					}
				);
				this.listMenu[2].submenu.sort(this.sortByName);
			},
			error: (e) => {
				console.log(e);
				this.listMenu[2].submenu.push(
					{
						id: 7,
						name: 'Parking',
						url: '/parking'
					},
					{
						id: 8,
						name: 'Otros servicios',
						url: '/otros-servicios'
					}
				);
				this.listMenu[2].submenu.sort(this.sortByName);
			}
		});
	}

	/*getMenuItemsCampaigns() {
		this._campaignService.getCampaign().subscribe({
			next: (res) => {
				res.forEach((campaign: any, index: number) => {
					this.listMenu[4].submenu.push({
						id: 2 + index + 1,
						name: campaign.nombre,
						url: `/campana/${campaign.id}`
					});
				});
			},
			error: (e) => {
				console.log(e);
			}
		});
	}*/

	@HostListener('window:scroll', ['$event'])
	onScroll() {
		if ((!this.isStaticDark && this._bannerService.isBannerActive) || this._bannerService.isErrorPage) {
			const height = window.scrollY;
			if (height >= 243) {
				this.isDark = true;
			} else {
				this.isDark = false;
			}
		}
	}

	openModalSesion() {
		const isLogin = this._authService.isLogged();
		this.isOpenSearch = false;
		if (isLogin == null) {
			this._modalService.open(SesionComponent, {
				scrollable: true,
				centered: true,
				backdrop: 'static',
				windowClass: 'modal-auth'
			});
		} else {
			this.router.navigateByUrl('/mi-cuenta');
		}
	}

	hoverNavbar() {
		this.isHover = true;
		this.isDark = true;
	}

	notHoverNavbar() {
		if (this.getScreenWidth > 960) {
			const height = window.scrollY;
			if ((!this.isStaticDark && this._bannerService.isBannerActive) || this._bannerService.isErrorPage) {
				this.isDark = height > 493 ? true : false;
			}
			this.isHover = false;
		}
		this.openSearch(false);
	}

	openNavbar(newState = !this.showNavbarOptions) {
		this.showNavbarOptions = newState;
		if (newState) {
			this.isDark = true;
			document.body.style.overflow = 'hidden';
		} else {
			this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: any) => {
				this.isDark =
					event.url == '/tiendas' ||
					event.url.includes('/eventos') ||
					event.url.includes('/descuentos') ||
					event.url == '/centros-finacieros' ||
					event.url == '/' ||
					event.url == '/gastronomia' ||
					event.url == '/sostenibilidad' ||
					event.url == '/artJockey'
						? !this._bannerService.isBannerActive
						: true;
			});
			document.body.style.overflow = 'auto';
		}
		this.clear();
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.hoverNavbar();
		if (this.getScreenWidth !== window.innerWidth) {
			this.openNavbar(false);
			this.getScreenWidth = window.innerWidth;
		}
	}

	goToHome() {
		this.router.navigateByUrl('/');
		this.openNavbar(false);
		this.isOpenSearch = false;
	}

	@ViewChild('navbarOptions') navbarOptions!: NavbarOptionsComponent;
	clear() {
		this.navbarOptions.openSubMenu('');
	}

	navigateTo() {
		this.router.navigateByUrl('/planea-tu-visita');
		this.isOpenSearch = false;
	}

	changeHoverSearch(isHover: boolean) {
		this.hoverSearch = isHover;
	}

	changeHoverUser(isHover: boolean) {
		this.hoverUser = isHover;
	}

	openSearch(isOpen: boolean) {
		this.isOpenSearch = isOpen;
	}

	openSubMenu(id: number) {
		this.activeSubmenu = id;
	}
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { URL_BACKEND } from '../../config/url';
import { ICampaign } from '../../interfaces/campaign.interface';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CampaignService {
	constructor(private _httpClient: HttpClient, private _router: Router) {}

	getCampaign(): Observable<ICampaign[]> {
		const url = `${URL_BACKEND}/activity/campaign/get`;
		return this._httpClient.get<ICampaign[]>(url).pipe(
			tap((res) => {
				return res;
			})
		);
	}

	showCampaign(id: number): Observable<ICampaign> {
		const url = `${URL_BACKEND}/activity/campaign/show?id=${id}`;
		return this._httpClient.get<ICampaign>(url).pipe(
			catchError((error: HttpErrorResponse) => {
				console.log('error', error);
				const statusCode = error.status;
				// Aquí puedes manejar códigos de estado específicos
				if (statusCode === 404) {
					this._router.navigateByUrl('404');
				}
				throw error;
			}),
			tap((res) => {
				if (res && res.bloqueInformativo) {
					res.bloqueInformativo.map((bloque) => {
						bloque.descripcionArray = bloque.descripcion?.split(/\n|\\n/);
						return bloque;
					});
				}
				return res;
			})
		);
	}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TagComponent } from './tag.component';
@NgModule({
	imports: [CommonModule],
	exports: [TagComponent],
	declarations: [TagComponent],
	providers: []
})
export class TagModule {}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, Optional } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AuthService } from '../services/api/auth.service';
import { catchError, of, tap, throwError } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalMessageComponent } from 'src/app/commons/elements-ui/modal-message/modal-message.component';
import { Router } from '@angular/router';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
import { ModalOfflineComponent } from 'src/app/commons/elements-ui/modal-offline/modal-offline.component';
import { LocalResolverService } from '../services/api/local-resolver.service';

@Injectable({
	providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
	status!: OnlineStatusType; //Enum provided by ngx-online-status
	onlineStatusCheck: any = OnlineStatusType;
	lastRoute = '';
	routesVisited: string[] = [];
	isOpenModal = false;
	constructor(
		private _authService: AuthService,
		private _modalService: NgbModal,
		private _router: Router,
		private onlineStatusService: OnlineStatusService,
		private _localHttp: LocalResolverService,
		@Optional() private _activeModal?: NgbActiveModal
	) {
		this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
			this.status = status;
			if (status == 0) {
				this._activeModal = this._modalService.open(ModalOfflineComponent, {
					scrollable: true,
					centered: true,
					backdrop: 'static',
					windowClass: 'modal-confirmation'
				});
			} else {
				if (this._activeModal) {
					this._activeModal.close();
				}
				const currentUrl = this._router.url;
				this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
					this._router.navigate([currentUrl]);
				});
			}
		});
	}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		const token = this._authService.isLogged();
		let request = req;
		request = req.clone({
			withCredentials: true,
			setHeaders: {
				'Access-Control-Allow-Origin': '*',
				'Referrer-Policy': 'strict-origin-when-cross-origin'
			}
		});

		//return next.handle(request).pipe(catchError((error: HttpErrorResponse) => this.errorHttp(error, token)));
		if (
			request.method == 'PUT' ||
			request.method == 'DELETE' ||
			request.url == `${window.location.href}/user/favourite` ||
			request.url.includes('listFavourite') ||
			request.url.includes('parking') ||
			request.url.includes('login') ||
			request.url.includes('register') ||
			request.url.includes('customer') ||
			request.url.includes('upload') ||
			request.url.includes('wishlist') ||
			request.url.includes('book') ||
			request.url.includes('salesforce') ||
			request.url.includes('search') ||
			request.url.includes('changePassword') ||
			request.url.includes('facebook') ||
			request.url.includes('notification') ||
			request.url.includes('setting') ||
			request.url.includes('arco')
		) {
			return next.handle(request).pipe(catchError((error: HttpErrorResponse) => this.errorHttp(error, token)));
		}

		const route =
			request.method == 'GET' ? request.url : request.url + JSON.stringify(req.body).replace(/[{}:",]/g, '');
		if (this.routesVisited.indexOf(route) > -1) {
			const cachedResponse = this._localHttp.getDataLocalHttp(route);
			if (cachedResponse) {
				return of(new HttpResponse({ body: cachedResponse }));
			} else {
				this.routesVisited.push(route);
				return this.sendRequest(request, next, token);
			}
		}

		this.routesVisited.push(route);
		return this.sendRequest(request, next, token);
	}

	sendRequest(request: HttpRequest<any>, next: HttpHandler, token: any) {
		return next.handle(request).pipe(
			tap((event) => {
				if (event instanceof HttpResponse) {
					const key =
						request.method == 'GET' ? request.url : request.url + JSON.stringify(request.body).replace(/[{}:",]/g, '');
					this._localHttp.setNewHttp(key, event.body);
				}
			}),
			catchError((error: HttpErrorResponse) => this.errorHttp(error, token))
		);
	}

	errorHttp(response: HttpErrorResponse, token: any) {
		if (response.status === 401 && token !== null && !this.isOpenModal) {
			this.isOpenModal = true;
			this._router.navigateByUrl('/');
			this._localHttp.deleteDataLocal('ccj');
			const modalRef = this._modalService.open(ModalMessageComponent, {
				scrollable: true,
				centered: true,
				backdrop: 'static',
				windowClass: 'modal-confirmation'
			});
			modalRef.componentInstance.title = 'Tu sesión caducó. Por favor, vuelve a iniciar sesión.';
			modalRef.componentInstance.icon = 'assets/icons/sesionexpired.svg';
			modalRef.componentInstance.isOpenLogin = true;
		}

		return throwError(response);
	}
}

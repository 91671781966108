import { Component } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'app-notification-alert',
	templateUrl: './notification-alert.component.html',
	styleUrls: ['./notification-alert.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class NotificationAlertComponent {
	isOpen = false;
	newNotifications = false;

	openNotifications(state?: boolean) {
		this.isOpen = state !== undefined ? state : !this.isOpen;
	}
}

import { NgModule } from '@angular/core';
import { DropdownMenuComponent } from './dropdown-menu.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
@NgModule({
	imports: [CommonModule, RouterModule],
	exports: [DropdownMenuComponent],
	declarations: [DropdownMenuComponent],
	providers: []
})
export class DropdownMenuModule {}

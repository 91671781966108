import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { MenuIconComponent } from './menu-icon/menu-icon.component';
import { NotificationAlertModule } from '../notification-alert/notification-alert.module';
import { AlertaModule } from '../alerta/alerta.module';

@NgModule({
	imports: [CommonModule, NotificationAlertModule, AlertaModule],
	exports: [SidebarComponent],
	declarations: [SidebarComponent, MenuIconComponent],
	providers: []
})
export class SidebarModule {}

import { NgModule } from '@angular/core';
import { ButtonLinkComponent } from './button-link.component';
import { RouterModule } from '@angular/router';
@NgModule({
	imports: [RouterModule],
	exports: [ButtonLinkComponent],
	declarations: [ButtonLinkComponent],
	providers: []
})
export class ButtonLinkModule {}

<nav
	class="navbar navbar-expand-lg navbar-web d-none d-xl-block"
	[class.dark]="isDark"
	(mouseenter)="hoverNavbar()"
	(scroll)="onScroll()"
	(mouseleave)="notHoverNavbar()"
>
	<section>
		<a [routerLink]="['/']">
			<img [src]="isDark ? 'assets/logo/JPHorizontalNegro.svg' : 'assets/logo/JPHorizontalBlanco.svg'" />
		</a>

		<div class="navbar__menu" *ngIf="!isOpenSearch">
			<ng-container *ngFor="let item of listMenu">
				<ng-container *ngIf="item.url === ''">
					<app-dropdown-menu
						[title]="item.name"
						[id]="item.id"
						[listItems]="item.submenu"
						[isDark]="isDark"
						[isHoverNavbar]="isHover"
						[isActive]="activeSubmenu === item.id"
						(clickSubmenu)="openSubMenu($event)"
					>
					</app-dropdown-menu>
				</ng-container>
				<ng-container *ngIf="item.url !== ''">
					<app-button-link [name]="item.name" [link]="item.url" [isDark]="isDark"></app-button-link>
				</ng-container>
			</ng-container>
		</div>

		<div class="navbar__search" *ngIf="isOpenSearch">
			<app-navbar-searcher (clickOpenSearch)="openSearch($event)"></app-navbar-searcher>
		</div>

		<div class="navbar__actions">
			<img
				[src]="
					hoverSearch
						? 'assets/icons/search-hover.svg'
						: isDark
						? 'assets/icons/search-dark.svg'
						: 'assets/icons/search-white.svg'
				"
				(mouseenter)="changeHoverSearch(true)"
				(mouseleave)="changeHoverSearch(false)"
				(click)="openSearch(true)"
				[hidden]="isOpenSearch"
			/>
			<img
				[src]="
					hoverUser
						? 'assets/icons/user-hover.svg'
						: isDark
						? 'assets/icons/user-dark.svg'
						: 'assets/icons/user-white.svg'
				"
				(mouseenter)="changeHoverUser(true)"
				(mouseleave)="changeHoverUser(false)"
				(click)="openModalSesion()"
			/>
			<a [routerLink]="['/planea-tu-visita']">
				<app-button
					[text]="'Planea tu visita'"
					[color]="'outline-primary'"
					[isWhite]="isDark ? false : true"
					[size]="'lg'"
					[random]="true"
				></app-button>
			</a>
		</div>
	</section>
</nav>
<!--mobile-->
<nav
	class="navbar navbar-expand-lg navbar-mobile"
	[class.dark]="isDark"
	[class.show__navbar__options]="showNavbarOptions"
	(scroll)="onScroll()"
>
	<div class="container__header__navbar">
		<div class="navbar__menu__button" (click)="openNavbar()">
			<svg
				class="button__menu"
				[ngClass]="isDark ? 'inactive__button__black' : 'inactive__button__white'"
				[class.active__button]="showNavbarOptions"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M3 6H21" stroke-width="1.5" stroke-linecap="round" />
				<path d="M3 12H21" stroke-width="1.5" stroke-linecap="round" />
				<path d="M3 18H21" stroke-width="1.5" stroke-linecap="round" />
			</svg>
		</div>
		<img
			[src]="isDark ? 'assets/logo/JPHorizontalNegro.svg' : 'assets/logo/JPHorizontalBlanco.svg'"
			(click)="goToHome()"
		/>
		<div class="navbar__menu__button">
			<svg
				[ngClass]="isDark ? 'inactive__button__black' : 'inactive__button__white'"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				(click)="openModalSesion()"
			>
				<path
					d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M20.59 22C20.59 18.13 16.74 15 12 15C7.26 15 3.41 18.13 3.41 22"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</div>
	</div>
	<div class="container__options" [class.container__options__active]="showNavbarOptions">
		<app-navbar-options
			#navbarOptions
			style="width: 100%; display: block"
			(clickLink)="openNavbar($event)"
			[listMenu]="listMenu"
			(clickSuggestionSearcher)="openNavbar()"
		></app-navbar-options>
	</div>
</nav>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputDateComponent } from './input-date.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DateMaskDirective } from 'src/app/core/directives/date-mask.directive';

@NgModule({
	imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbModule],
	exports: [InputDateComponent],
	declarations: [InputDateComponent, DateMaskDirective],
	providers: []
})
export class InputDateModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertaComponent } from './alerta.component';
@NgModule({
	imports: [CommonModule],
	exports: [AlertaComponent],
	declarations: [AlertaComponent],
	providers: []
})
export class AlertaModule {}

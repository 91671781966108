import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URL_BACKEND } from '../../config/url';
import { ILink } from '../../interfaces/link-interface';

@Injectable({ providedIn: 'root' })
export class LinksExtService {
	constructor(private _httpClient: HttpClient) {}
	dataExternalLinks: ILink[] = [];
	getLinks(nombre = ''): Observable<ILink[]> {
		const url = `${URL_BACKEND}/content/link/get`;
		return this._httpClient.post<ILink[]>(url, nombre === '' ? null : { nombre });
	}

	searchLink(nombre: string) {
		const url = this.dataExternalLinks.find((o) => o.nombre === nombre);
		return url ? url.url : '#';
	}
}

<label [class.disabled]="disabled"
	>{{ isPhone ? 'Télefono' : 'Celular' }}
	<span *ngIf="isPhone && isRequired && showRequired" class="span__label__required">*</span></label
>
<div class="container__phone" [class.error]="phoneField.touched && phoneField!.invalid && isRequired">
	<div class="container__phone__select" (click)="showItemsCountries()" [class.disabled]="disabled">
		<div class="container__phone__select__country">
			<i class="flag" [ngClass]="flagSeleted && 'flag-' + flagSeleted.codeLower"></i>
			<span [class.disabled]="disabled">{{ flagSeleted.dial_code }}</span>
		</div>
		<img src="assets/icons/arrow-down-dark.svg" alt="v" />
	</div>

	<div class="container__phone__input">
		<form [formGroup]="form">
			<input
				#inputNumber
				type="number"
				onwheel="return false;"
				oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
				formControlName="valuePhone"
				[class.error]="phoneField.touched && phoneField!.invalid && isRequired"
				(focusout)="onFocusOutEvent()"
				[readonly]="disabled"
				[maxlength]="maxlength"
			/>
		</form>
		<span *ngIf="phoneField.touched && phoneField!.hasError('required') && isRequired">Número es obligatorio</span>
		<span *ngIf="phoneField.touched && phoneField!.hasError('pattern') && isRequired">Número inválido</span>
	</div>
	<div class="container__phone__resultBox" *ngIf="showItems">
		<ul>
			<input
				type="text"
				class="container__phone__resultBox__input"
				placeholder="Search Country"
				[formControl]="valueSearch"
			/>
			<li
				*ngFor="let flag of flags"
				(click)="clickItem(flag); inputNumber.focus()"
				[class.active]="flag.dial === flagSeleted.dial"
			>
				<i class="flag" [ngClass]="flag && 'flag-' + flag.codeLower"></i>
				<span>{{ flag.name }} {{ '(' + flag.dial_code + ')' }}</span>
			</li>
		</ul>
	</div>
</div>

<div class="form-check">
	<input
		class="form-check-input"
		type="checkbox"
		[checked]="checked"
		[name]="name"
		[formControlName]="name"
		[id]="name"
	/>
	<label class="form-check-label" [for]="name"
		>{{ label }}
		<a *ngIf="textLink !== ''" [href]="url" target="_blank">{{ textLink }}</a>
	</label>
</div>
<span class="span__error" *ngIf="labelError !== ''">{{ labelError }}</span>

import { NgModule } from '@angular/core';
import { InputComponent } from './input.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnlyLettersDirective } from 'src/app/core/directives/only-letters.directive';
@NgModule({
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	exports: [InputComponent],
	declarations: [InputComponent, OnlyLettersDirective],
	providers: []
})
export class InputModule {}

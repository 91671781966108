/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'jockey-website-web';
	constructor(private meta: Meta, private renderer: Renderer2) {}
	ngOnInit() {
		const metaTags = [{ property: 'og:url', content: environment.api_url }];

		metaTags.forEach((tag: any) => this.meta.addTag(tag));

		//Add Link preconnect
		const link = this.renderer.createElement('link');
		this.renderer.setAttribute(link, 'rel', 'preconnect');
		this.renderer.setAttribute(link, 'href', environment.api_url);
		this.renderer.appendChild(document.head, link);
	}
}

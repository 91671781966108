import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-title',
	templateUrl: './title.component.html',
	styleUrls: ['./title.component.scss']
})
export class TitleComponent {
	@Input() text?: string = '';
	@Input() variant?: string = 'T3';
	@Input() line?: boolean = false;
	@Input() center?: boolean = false;
}

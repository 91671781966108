export const environment = {
	production: true,
	token: '==54Krws597!Tgk&eD',
	api_url: 'https://jp-api-test.delfosti.site',
	timeRenderCards: 3000,
	timeAnimationSlider: 5000,
	recaptcha: '6Ldoi4QkAAAAAKcNgWk0gKulYSSR-hwEaUHooqwz',
	recaptchav2: '6Lf-j-YkAAAAAN2ece9rE7MoKugqe45ryb0gia-d',
	keyLocalStorage: 'jockeyLocal2023',
	endpointAforo: '',
	keyGoogleMaps: 'AIzaSyAFOFTWqQtmhSnE2sBeJ0Om2xU4er0UQEk',
	venueMappedin: 'jockey-plaza-peru',
	clientIdMappedin: '63d95956c812ca001d1409ab',
	clientSecretMappedin: '5p3CTxPIzxpos5bie9N2kwyhFpm0aAzMA0NzKNPLciRJzduS',
	clientIdFacebook: '4123522814540550',
	clientSecretFacebook: '6c41431196e833148580e8327ba6ef96',
	versionFacebook: 'v15.0',
	urlMedia: 'https://jp-website-test.delfosti.site/media'
};

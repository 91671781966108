import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarOptionsComponent } from './navbar-options.component';
import { NavBarSearchModule } from '../navbar-searcher/navbar-searcher.module';
@NgModule({
	imports: [CommonModule, RouterModule, NavBarSearchModule],
	exports: [NavbarOptionsComponent],
	declarations: [NavbarOptionsComponent],
	providers: []
})
export class NavBarOptionsModule {}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/api/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalMessageComponent } from 'src/app/commons/elements-ui/modal-message/modal-message.component';
import { SesionComponent } from '../sesion/sesion.component';
import { FacebookService, InitParams, LoginOptions, LoginResponse } from 'ngx-facebook';
import { environment } from 'src/environments/environment';
import { LocalResolverService } from 'src/app/core/services/api/local-resolver.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	formGroup!: FormGroup;

	formObject = {
		CorreoElectronico: new FormControl('', [
			Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/),
			Validators.required
		]),
		Contrasenia: new FormControl('', Validators.required),
		PermanecerConectado: new FormControl(false)
	};
	@Output() clickButton = new EventEmitter<boolean>();
	@Output() clickClose = new EventEmitter();
	@Output() showAlertError = new EventEmitter<string>();
	@Output() clickForgetPassword = new EventEmitter<boolean>();
	messageDefault = 'Parece que tu correo electrónico o contraseña son incorrectos. Por favor, inténtalo nuevamente';
	constructor(
		private _router: Router,
		private _authService: AuthService,
		private _modalService: NgbModal,
		private fb: FacebookService,
		private _localService: LocalResolverService
	) {
		this.getScreenWidth = window.innerWidth;
		this.formGroup = new FormGroup(this.formObject);
		const initParams: InitParams = {
			appId: environment.clientIdFacebook,
			xfbml: true,
			version: environment.versionFacebook,
			cookie: true,
			status: true
		};
		fb.init(initParams);
	}

	user: any;
	loggedIn: any;
	isProcess = false;
	isProcessFacebook = false;
	isFinish = false;
	getScreenWidth = 0;

	options: LoginOptions = {
		return_scopes: true,
		scope: 'email,public_profile',
		enable_profile_selector: true
	};

	signInWithFB() {
		this.fb.login(this.options).then((response: LoginResponse) => {
			this.generateToken(response);
		});
	}

	generateToken(response: LoginResponse) {
		const url = `https://graph.facebook.com/oauth/access_token?grant_type=fb_exchange_token&client_id=${environment.clientIdFacebook}&client_secret=${environment.clientSecretFacebook}&fb_exchange_token=${response.authResponse.accessToken}`;
		this.fb
			.api(url)
			.then((res) => {
				this.loginWithFacebook(res.access_token, response.authResponse.userID);
			})
			.catch(() => this.showAlert('Hubo un error con el Servicio de Facebook. Por favor, inténtalo más tarde'));
	}

	loginWithFacebook(token: string, idUser: string) {
		this.isProcessFacebook = true;
		this._authService
			.loginWithFacebook({
				IdFacebook: idUser,
				FacebookToken: token,
				PermanecerConectado: true
			})
			.subscribe({
				next: (res) => {
					if (res.message === 'Cuenta no verificada') {
						this.showModalMessage();
					}

					if (res.message === 'Correo y/o contraseña invalida') {
						this.showAlert(
							'No tienes una cuenta conectada a tu cuenta de Facebook. Puedes iniciar sesión con tus credenciales o registrarte.'
						);
					}

					if (res.cliente?.emailVerificado) {
						this._authService.setUserLogin({
							id: res.cliente.id,
							email: res.cliente.correoElectronico
						});
						this.closeModal();
						this._localService.setNewLocal('lfb', 'true');
						this._router.navigateByUrl('/mi-cuenta');
					}
					this.isProcessFacebook = false;
				},
				error: () => {
					this.showAlert('Al parecer ocurrio un error, intentelo más tarde');
					this.isProcessFacebook = false;
				}
			});
	}

	ngOnInit(): void {
		this.rememberLogin();
	}

	rememberLogin() {
		const credentials = this._authService.getCredentials();
		if (credentials !== null && credentials.CorreoElectronico) {
			this.emailField.setValue(credentials.CorreoElectronico);
			this.passwordField.setValue(credentials.Contrasenia);
			this.rememberField.setValue(true);
		}
	}

	showRegisterSection() {
		this.clickButton.emit(false);
	}

	closeModal() {
		this.clickClose.emit();
	}

	loginWithEmail() {
		if (this.formGroup.valid) {
			this.isProcess = true;
			const values = this.formGroup.value;
			this._authService.loginWithEmail(values).subscribe({
				next: (res) => {
					this.isProcess = false;
					if (res.message === 'Cuenta no verificada') {
						this.showModalMessage();
					}

					if (res.message === 'Correo y/o contraseña invalida' || res.cliente === null) {
						this.showAlert(res.message);
					}

					if (res.cliente?.emailVerificado) {
						this._authService.setUserLogin({
							id: res.cliente.id,
							email: res.cliente.correoElectronico
						});
						if (this.rememberField.value) {
							this._authService.setCredentials(values);
						} else {
							this._authService.deleteCredentials();
						}
						this.closeModal();
						this._router.navigateByUrl('/mi-cuenta');
					}
				},
				error: (e) => {
					console.log(e);
					this.showAlert('Al parecer ocurrio un error, intentelo más tarde');
					this.isProcess = false;
				}
			});
		} else {
			this.showAlert(this.messageDefault);
			this.formGroup.markAllAsTouched();
		}
	}

	showAlert(message: string) {
		this.showAlertError.emit(message);
	}

	forgetPassword() {
		this.clickForgetPassword.emit(true);
	}

	showModalMessage() {
		this.closeModal();
		const modalRef = this._modalService.open(ModalMessageComponent, {
			scrollable: true,
			centered: true,
			backdrop: 'static',
			windowClass: 'modal-confirmation'
		});
		modalRef.componentInstance.title =
			'Se envió un correo de activación de su cuenta, por favor verifique para iniciar sesión.';
		modalRef.componentInstance.clickButton.subscribe(() => {
			this._modalService.open(SesionComponent, {
				scrollable: true,
				centered: true,
				backdrop: 'static',
				windowClass: 'modal-auth'
			});
		});
	}

	get passwordField(): AbstractControl {
		return this.formGroup.get('Contrasenia')!;
	}

	get emailField(): AbstractControl {
		return this.formGroup.get('CorreoElectronico')!;
	}

	get rememberField(): AbstractControl {
		return this.formGroup.get('PermanecerConectado')!;
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.getScreenWidth = window.innerWidth;
	}
}

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertaModule } from '../commons/elements-ui/alerta/alerta.module';
import { ButtonLinkModule } from '../commons/elements-ui/button-link/button-link.module';
import { ButtonModule } from '../commons/elements-ui/button/button.module';
import { DropdownMenuModule } from '../commons/elements-ui/dropdown-menu/dropdown-menu.module';
import { InputCheckModule } from '../commons/elements-ui/input-check/input-check.module';
import { InputSearchModule } from '../commons/elements-ui/input-search/input-search.module';
import { InputModule } from '../commons/elements-ui/input/input.module';
import { NotResultModule } from '../commons/elements-ui/not-result/not-result.module';
import { PaginationModule } from '../commons/elements-ui/pagination/pagination.module';
import { TagModule } from '../commons/elements-ui/tag/tag.module';
import { TitleModule } from '../commons/elements-ui/title/title.module';

@NgModule({
	exports: [
		ReactiveFormsModule,
		FormsModule,
		ButtonModule,
		InputModule,
		TitleModule,
		TagModule,
		AlertaModule,
		InputCheckModule,
		DropdownMenuModule,
		ButtonLinkModule,
		PaginationModule,
		InputSearchModule,
		NotResultModule
	]
})
export class FormSharedModule {}

/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, debounceTime, filter, tap, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Component({
	selector: 'app-input-search',
	templateUrl: './input-search.component.html',
	styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent implements OnInit, OnDestroy {
	@Input() placeholder?: string;
	@Input() class?: string;
	@Input() getSuggestionItems!: (args: string) => Observable<any[]>;
	@Output() clickItem: EventEmitter<any> = new EventEmitter<any>();
	@Output() clear = new EventEmitter<string>();
	@Output() clickSeeAllResults = new EventEmitter<string>();
	constructor() {}

	valueSearch = new FormControl('');
	private destroy$ = new Subject<unknown>();

	listSuggestions: any[] = [];
	listResult: any[] = [];
	isClickSuggestion = false;
	word = '';

	ngOnInit(): void {
		this.onChangeSearch();
	}

	clickSuggestion(suggestionWord: string, item: any = {}) {
		this.listSuggestions = [];
		if (suggestionWord.trim().length !== 0) {
			this.isClickSuggestion = true;
			this.valueSearch.setValue('');
			if (item.id !== undefined) {
				if (item.flag && item.flag.estado === 'Muy Pronto' && new Date(item.flag.fechaExpiracion) >= new Date()) {
					this.clickItem.emit({
						suggestionWord,
						id: item.id,
						isLabel: false,
						card: item
					});
				} else {
					this.clickItem.emit({
						suggestionWord,
						id: item.id,
						isLabel: item.imagenDirectorioLogo !== undefined ? false : true
					});
				}
			} else {
				this.clickItem.emit({ suggestionWord, id: 0, isLabel: false });
			}
		} else {
			this.clear.emit();
		}
		this.isClickSuggestion = false;
		this.destroy$;
	}

	seeAllResults(suggestionWord: string) {
		this.valueSearch.setValue('');
		if (suggestionWord.trim().length !== 0) {
			this.clickSeeAllResults.emit(suggestionWord);
		} else {
			this.clear.emit();
		}
		this.destroy$;
	}

	private onChangeSearch(): void {
		this.valueSearch.valueChanges
			.pipe(
				map((search) => search?.toLowerCase().trim()),
				tap((search) => {
					if (search == undefined || search?.length < 2) {
						this.word = '';
						this.listSuggestions = [];
						if (search == '') {
							this.clear.emit();
						}
					}
					return search;
				}),
				filter((search) => search !== '' && search !== undefined && search?.length > 1),
				debounceTime(500),
				tap((search) => this.onSearchService(search)),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	onSearchService(search = ''): void {
		this.word = search;
		console.log('dasdas');
		const arraySuggestions: any[] = [];
		if (!this.isClickSuggestion) {
			this.getSuggestionItems(search).subscribe((res) => {
				this.listResult = res;
				search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
				this.listResult.reduce((results, item) => {
					const word = item.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
					const coincidence = word.match(new RegExp(search, 'gi'));
					const strRegExp = new RegExp(search, 'gi');
					let html;
					if (coincidence.length > 1) {
						let wordAux = JSON.parse(JSON.stringify(word));
						for (let i = 0; i < coincidence.length; i++) {
							const coincidencia = new RegExp(coincidence[i], 'gi');
							wordAux = wordAux.replace(coincidencia, '<strong>$&</strong>');
						}
						html = wordAux;
					} else html = word.replace(strRegExp, `<strong>${coincidence}</strong>`);
					if (html.charAt(0) == '<') {
						const letter = html.charAt(8);
						const chars: any[] = html.split('');
						chars[8] = letter.toUpperCase();
						html = chars.join('');
					}
					results.push({ ...item, wordHtml: html });
					return results;
				}, arraySuggestions);
				console.log('arraySuggestions', arraySuggestions);
				this.listSuggestions = arraySuggestions;
			});
		} else {
			this.isClickSuggestion = false;
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next({});
		this.destroy$.complete();
	}

	clickClear() {
		this.valueSearch.setValue('');
	}
}

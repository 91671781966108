import { Component } from '@angular/core';
import { BannerService } from 'src/app/core/services/api/banner-api.service';

@Component({
	selector: 'app-my-account-layout',
	templateUrl: './my-account-layout.component.html',
	styleUrls: ['./my-account-layout.component.scss']
})
export class MyAccountLayoutComponent {
	constructor(public _bannerService: BannerService) {
		window.scroll({ top: 0, behavior: 'smooth' });
	}
}

import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { ISubMenu } from 'src/app/core/interfaces/menu.interface';

@Component({
	selector: 'app-dropdown-menu',
	templateUrl: './dropdown-menu.component.html',
	styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent implements OnInit, OnChanges {
	@Input() isDark = false;
	@Input() isHoverNavbar = false;
	@Input() listItems: ISubMenu[] = [];
	@Input() title!: string;
	@Input() id!: number;
	@Input() isActive = false;
	@Output() clickSubmenu = new EventEmitter<number>();
	imgDropdownDark = 'assets/icons/arrow-down-dark.svg';
	imgDropdownWhite = 'assets/icons/arrow-down-white.svg';
	imgDropdownHover = 'assets/icons/arrow-down-hover.svg';
	imgDropdown = '';
	showItems = false;
	constructor(private _router: Router) {
		this._router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
			this.showItems = false;
		});
	}
	ngOnInit(): void {
		this.imgDropdown = this.isDark ? this.imgDropdownDark : this.imgDropdownWhite;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['isDark']) {
			this.imgDropdown = changes['isDark'].currentValue ? this.imgDropdownDark : this.imgDropdownWhite;
		}

		if (changes['isHoverNavbar']) {
			if (!changes['isHoverNavbar'].currentValue) {
				this.showItems = false;
			}
		}

		if (changes['isActive']) {
			if (!changes['isActive'].currentValue) this.showItems = false;
		}
	}

	hoverDropdown(isHover: boolean) {
		this.imgDropdown = isHover ? this.imgDropdownHover : this.imgDropdownDark;
	}

	navigatePage(link: string) {
		this._router.navigateByUrl(link);
	}

	clickDropdown() {
		if (!this.showItems) this.clickSubmenu.emit(this.id);
		this.showItems = !this.showItems;
	}
}

export const dataMenu = [
	{
		id: 1,
		name: 'Directorio',
		url: '',
		submenu: [
			{
				id: 1,
				name: 'Tiendas',
				url: '/tiendas'
			},
			{
				id: 2,
				name: 'Gastronomía',
				url: '/gastronomia'
			},
			{
				id: 3,
				name: 'Centro Financiero',
				url: '/centros-finacieros'
			}
		]
	},
	{
		id: 2,
		name: 'Descuentos J',
		url: '/descuentos',
		submenu: []
	},
	{
		id: 3,
		name: 'Servicios',
		url: '',
		submenu: [
			{
				id: 1,
				name: 'Asistencia',
				url: '/servicios-de-asistencia'
			}
		]
	},
	{
		id: 4,
		name: 'Eventos',
		url: '/eventos',
		submenu: []
	},
	{
		id: 5,
		name: 'Cultura',
		url: '',
		submenu: [
			{
				id: 1,
				name: 'Art Jockey',
				url: '/artJockey'
			},
			{
				id: 2,
				name: 'Sostenibilidad',
				url: '/sostenibilidad'
			}
		]
	}
];

export const serviciosUrl = [
	{
		id: 2,
		name: 'Personal Shopper',
		url: '/personal-shopper'
	},
	{
		id: 3,
		name: 'Giftealo',
		url: '/giftealo'
	},
	{
		id: 3,
		name: 'Takeit',
		url: '/takeit'
	},
	{
		id: 4,
		name: 'Guarda compras',
		url: '/guarda-compras'
	},
	{
		id: 5,
		name: 'Objetos perdidos',
		url: '/objetos-perdidos'
	},
	{
		id: 6,
		name: 'TikTok room',
		url: '/tiktok-room'
	}
];

<div
	*ngIf="!bannerService.hiddenWidgetSocial"
	class="container_side_card_set"
	[ngClass]="stopSocialNetworks ? 'side_card_set_absolute_container' : 'side_card_set_fixed_container'"
	[class.side_card_set_hidden]="isActiveCardSet"
	[style.bottom]="stopSocialNetworks ? bottomAbsolutePosition + 'px' : '260px'"
	(click)="isActiveCardSet = !isActiveCardSet"
>
	<div class="side_card_set_info_container w-100">
		<div class="side_card_set_big_circle"></div>
		<div class="side_card_set_small_circle"></div>
		<div class="side_card_set_medum_circle"></div>
		<div class="side_card_set_info_body">
			<span class="text-uppercase">Juntos contra el bullying</span>
			<div class="side_card_set_info_body_button_link">
				<span>¡Súmate al cambio!</span>
			</div>
		</div>
	</div>
</div>

/* eslint-disable @angular-eslint/no-output-native */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-tag',
	templateUrl: './tag.component.html',
	styleUrls: ['./tag.component.scss']
})
export class TagComponent {
	@Input() text = '';
	@Input() active?: boolean = false;
	@Output() click = new EventEmitter<MouseEvent>();
}

<div class="btn-group" (mouseover)="hoverDropdown(true)" (mouseout)="hoverDropdown(false)">
	<a class="drop" id="dropdownMenuButton" [class.dark]="isDark" (click)="clickDropdown()">
		{{ title }}
		<img [src]="imgDropdown" alt="v" />
	</a>
	<div class="dropdown-menu-container" *ngIf="showItems">
		<a class="dropdown-item" *ngFor="let item of listItems" [routerLink]="[item.url]">
			{{ item.name }}
		</a>
	</div>
</div>

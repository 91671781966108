<span *ngIf="label" class="span__label" [class.disabled]="disabled"
	>{{ label }} <span *ngIf="isRequired" class="span__label__required">*</span>
</span>
<form class="group-input" [formGroup]="form" [ngClass]="inputClass">
	<input
		[type]="'text'"
		placeholder="dd/mm/yyyy"
		[value]="dateDefault"
		[class.label]="label"
		class="icon"
		formControlName="valueDate"
		[readonly]="disabled"
		role="presentation"
		autocomplete="off"
		list="autocompleteOff"
		ngbDatepicker
		#dp="ngbDatepicker"
		(click)="dp.toggle()"
		[firstDayOfWeek]="0"
		[placement]="'bottom'"
		(focusout)="onFocusOutEvent()"
		(change)="onFocusOutEvent()"
		[minDate]="{ year: 1900, month: 1, day: 1 }"
		[maxDate]="currentDate"
		appDateMask
	/>
	<img [src]="'assets/icons/calendar-2.svg'" (click)="dp.toggle()" />
</form>
<span class="span__error" *ngIf="dateField.touched && dateField!.hasError('required') && isRequired">
	La fecha obligatoria
</span>
<span class="span__error" *ngIf="dateField.touched && dateField!.hasError('isInvalidDate')"> Fecha inválida </span>

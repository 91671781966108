import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MyAccountLayoutComponent } from './my-account-layout.component';
import { SidebarModule } from '../../elements-ui/sidebar/sidebar.module';
import { NavBarModule } from '../../elements-ui/navbar/navbar.module';
import { FooterModule } from '../../elements-ui/footer/footer.module';

@NgModule({
	declarations: [MyAccountLayoutComponent],
	imports: [CommonModule, RouterModule, SidebarModule, NavBarModule, FooterModule],
	exports: [MyAccountLayoutComponent]
})
export class MyAccountLayoutModule {}

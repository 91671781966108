/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BannerService } from 'src/app/core/services/api/banner-api.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-sesion',
	templateUrl: './sesion.component.html',
	styleUrls: ['./sesion.component.scss'],
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [style({ opacity: 0 }), animate('900ms ease', style({ opacity: 1 }))]),
			transition(':leave', [style({ opacity: 0 })])
		]),
		trigger('fadeTerms', [
			transition(':enter', [style({ transform: 'translateX(100%)' }), animate(500)]),
			transition(':leave', [style({ transform: 'translateX(-100%)' }), animate(500)])
		])
	]
})
export class SesionComponent implements OnInit, OnDestroy {
	isLogin = true;
	classAuth = '';
	positionXImage = 0;
	positionXForm = 0;
	getScreenWidth = 0;
	messageError = '';
	showAlert = false;
	isAccpetTerms!: boolean | undefined;
	showSectionForget = false;
	isRecoveryPass = false;
	imageSesion = `${environment.urlMedia}/login`;

	constructor(public activeModal: NgbActiveModal, private _bannerService: BannerService) {}

	ngOnInit(): void {
		document.body.classList.add('show__scroll');
		this.getScreenWidth = window.innerWidth;
	}

	closeModal() {
		document.documentElement.style.setProperty('--visibility', 'hidden');
		this._bannerService.hiddenWidgetSocial = false;
		this.activeModal.close();
	}

	changeModalDisplayed(value: boolean, isForget = false): void {
		this.isLogin = value;
		this.positionXForm = !value ? this.positionXForm + 536 : this.positionXForm - 536;
		this.positionXImage = !value ? this.positionXImage - 560 : this.positionXImage + 560;
		const hideCaptcha = value ? 'hidden' : 'visible';
		document.documentElement.style.setProperty('--visibility', hideCaptcha);
		this.classAuth = value ? 'isLogin' : 'isRegister';
		this.imageSesion = this.isLogin
			? `${environment.urlMedia}/login`
			: isForget
			? `${environment.urlMedia}/recoverPassword`
			: `${environment.urlMedia}/register`;
		if (this.getScreenWidth < 1200) {
			this.positionTopScroll(document.getElementById('modalContentRefMob'));
		} else {
			this.positionTopScroll(document.getElementById('modalContentRef'));
		}
		this.isAccpetTerms = undefined;
		this._bannerService.hiddenWidgetSocial = true;
	}

	positionTopScroll(elementId: any) {
		if (elementId !== null) {
			elementId.scrollIntoView();
			elementId = null;
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.getScreenWidth = window.innerWidth;
	}

	showAlertLogin(message: string) {
		this.showAlert = true;
		this.messageError = message;
	}

	showAlertRegister(message: string) {
		this.messageError = message;
		if (message.includes('*')) {
			this.isRecoveryPass = true;
			this.messageError = message.slice(0, -1);
		} else {
			this.isRecoveryPass = false;
		}
		this.showAlert = true;
	}

	showSectionPassword($event: boolean) {
		this.showSectionForget = $event;
		this.changeModalDisplayed(!$event, true);
	}

	closeAlert() {
		this.showAlert = false;
	}

	ngOnDestroy() {
		document.body.classList.remove('show__scroll');
	}
}

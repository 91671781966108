/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	HostListener,
	ViewChild,
	Input,
	SimpleChanges,
	OnChanges
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { dataGenero, dataTipoDocumento } from './data.intereses';
import { CustomValidators } from 'src/app/core/validators/newpassword.validator';
import { InputPhoneComponent } from 'src/app/commons/elements-ui/input-phone/input-phone.component';
import { InputDocumentComponent } from 'src/app/commons/elements-ui/input-document/input-document.component';
import { AuthService } from 'src/app/core/services/api/auth.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ModalMessageComponent } from 'src/app/commons/elements-ui/modal-message/modal-message.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Interest } from 'src/app/core/interfaces/auth.interface';
import { LinksExtService } from 'src/app/core/services/api/links-ext-api.service';
import { FacebookService, InitParams, LoginOptions, LoginResponse } from 'ngx-facebook';
import { environment } from 'src/environments/environment';
import { InputDateComponent } from 'src/app/commons/elements-ui/input-date/input-date.component';
import { Router } from '@angular/router';
import { LocalResolverService } from 'src/app/core/services/api/local-resolver.service';
import { formatBirthdate } from 'src/app/core/functions/formatDate';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnChanges {
	active = 1;
	disabledTab2 = true;
	disabledTab3 = true;
	formGroup!: FormGroup;
	formGroupDatos!: FormGroup;
	formGroupRecover!: FormGroup;
	disableButton = false;
	listIntereses: Interest[] = [];
	listGenero = dataGenero;
	listTipoDocumento = dataTipoDocumento;
	separateDialCode = true;
	isProcess = false;
	isFinish = false;
	isEmailNotRegister = false;
	getScreenWidth = 0;
	isPatchValueRegister = false;
	isValidEmail = true;
	emailVerify = '@';
	isValidPhone = false;
	isValidDocument = false;
	isEndScroll = false;
	fullCelular = '';
	isCheckFacebook = false;
	isProcessFacebook = false;
	fullDate = '';
	colors = ['blue', 'red', 'yellow', 'green'];
	messageDefault = 'Parece que algunos de tus datos son inválidos. Por favor, inténtalo nuevamente.';
	isEmailDelivered = false;
	birthdate = '';

	options: LoginOptions = {
		return_scopes: true,
		scope: 'email,public_profile',
		enable_profile_selector: true
	};

	@Input() isChecked!: boolean | undefined;
	@Input() showSectionForget = false;
	@Output() clickButton = new EventEmitter<boolean>();
	@Output() clickClose = new EventEmitter();
	@Output() showAlertError = new EventEmitter<string>();
	@Output() hideAlertError = new EventEmitter<string>();
	@Output() clickTerm = new EventEmitter<boolean>();
	@Output() clickHideForget = new EventEmitter<boolean>();
	@ViewChild('childPhone')
	childPhone!: InputPhoneComponent;
	@ViewChild('childDocument')
	childDocument!: InputDocumentComponent;
	@ViewChild('childBirthdate')
	childBirthdate!: InputDateComponent;

	formObject = {
		CorreoElectronico: new FormControl('', [
			Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/),
			Validators.required,
			this.noWhitespaceValidator
		]),
		Contrasenia: new FormControl('', [
			Validators.required,
			CustomValidators.patternValidator(/\d/, {
				hasNumber: true
			}),
			CustomValidators.patternValidator(/[A-Z]/, {
				hasCapitalCase: true
			}),
			CustomValidators.patternValidator(/^[a-zA-Z0-9]+$/, {
				hasSpecialCharacters: true
			}),
			Validators.minLength(8)
		]),
		TerminosCondiciones: new FormControl(false, [Validators.requiredTrue]),
		PoliticasRedes: new FormControl(false)
	};

	formObjectDatos = {
		Id: new FormControl(0),
		RecibirCorreo: new FormControl(true),
		SesionFacebook: new FormControl(false),
		SesionGmail: new FormControl(false),
		DispositivoId: new FormControl('web'),
		PoliticaPrivacidad: new FormControl(true),
		IdFacebook: new FormControl(''),
		FacebookToken: new FormControl(''),
		intereses: new FormControl([]),
		imagenPerfil: new FormControl(''),
		permanecerConectado: new FormControl(true),
		Nombre: new FormControl('', [Validators.required, Validators.minLength(2), this.noWhitespaceValidator]),
		Apellido: new FormControl('', [Validators.required, Validators.minLength(2), this.noWhitespaceValidator]),
		fechaNacimiento: new FormControl(null, [Validators.required]),
		genero: new FormControl(null, [Validators.required]),
		TipoDocumentoId: new FormControl(1),
		Identificacion: new FormControl(''),
		NumeroCelular: new FormControl(''),
		PaisCelular: new FormControl('+51')
	};

	formObjectRecover = {
		emailRecover: new FormControl('', [Validators.email, Validators.required])
	};

	noWhitespaceValidator(control: any) {
		const isWhitespace = (control.value || '').trim().length === 0;
		const startWithSpace = (control.value || '')[0] === ' ';
		const isValid = !(isWhitespace || startWithSpace);
		return isValid ? null : { whitespace: true };
	}

	constructor(
		private _authService: AuthService,
		private recaptchaV3Service: ReCaptchaV3Service,
		private _modalService: NgbModal,
		public _linksExtService: LinksExtService,
		private fb: FacebookService,
		private _router: Router,
		private _localService: LocalResolverService
	) {
		this.formGroup = new FormGroup(this.formObject);
		this.formGroupDatos = new FormGroup(this.formObjectDatos);
		this.formGroupRecover = new FormGroup(this.formObjectRecover);
		const initParams: InitParams = {
			appId: environment.clientIdFacebook,
			xfbml: true,
			version: environment.versionFacebook,
			cookie: true,
			status: true
		};
		fb.init(initParams);
	}

	ngOnInit(): void {
		this.getScreenWidth = window.innerWidth;
		this.getListInterest();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['isChecked']) {
			if (changes['isChecked'].currentValue !== undefined) {
				this.acceptTermsField.patchValue(this.isChecked);
				this.emailField.patchValue(this._authService.authTemporal.CorreoElectronico);
				this.passwordField.patchValue(this._authService.authTemporal.Contrasenia);
				this.formGroup.markAllAsTouched();
			}
		}
	}

	registerStepOne() {
		this.isCheckFacebook = false;
		if (this.formGroup.valid) {
			this.hideAlertError.emit();
			this.isProcess = true;
			this._authService.validEmailByRegister(this.emailField.value).subscribe({
				next: (res) => {
					this.isProcess = false;
					if (res) {
						this.isValidEmail = false;
						this.disabledTab2 = true;
						this.emailVerify = '@';
					}

					if (!res) {
						this.active = 2;
						this.disabledTab2 = false;
						this.emailVerify = this.emailField.value;
					}
				},
				error: () => {
					this.isProcess = false;
					this.showAlert('Al parecer hubo un error al validar el email, inténtelo mas tarde');
				}
			});
		} else {
			this.showAlert(this.messageDefault);
			this.disabledTab2 = true;
			this.formGroup.markAllAsTouched();
		}
	}

	registerStepTwo() {
		const phone = this.childPhone.getPhoneSelected();
		const document = this.childDocument.getDocumentSelected();
		const birthdate = this.childBirthdate.getDateSelected();
		if (this.formGroupDatos.valid && phone.isValid && document.isValid && birthdate.isValid) {
			this.hideAlertError.emit();
			this.active = 3;
			this.disabledTab3 = false;
		} else {
			this.showAlert(this.messageDefault);
			this.disabledTab3 = true;
			this.formGroupDatos.markAllAsTouched();
		}
	}

	showAlert(message: string, isRecover = false) {
		this.showAlertError.emit(isRecover ? message + '*' : message);
	}

	registerStepTree(isOmit: boolean) {
		this.hideAlertError.emit();
		const previntereses = isOmit ? [] : this.listIntereses.filter((item) => item.selected == true);
		const intereses: string[] = [];
		previntereses.forEach((element) => {
			intereses.push(element.nombre);
		});
		this.isProcess = true;
		const numberRandom = Math.ceil(Math.random() * 4);
		const fullCel = this.dialField.value + '.' + this.phoneField.value;
		this.fullCelular = fullCel;
		this.fullDate = this.dateBirthField.value;
		this.interestField.setValue(intereses);
		this.imageField.setValue(this.colors[numberRandom - 1]);
		this.phoneField.setValue(fullCel);

		const allData = { ...this.formGroupDatos.value, ...this.formGroup.value };
		this._authService.registerWithEmail(allData).subscribe({
			next: (res) => {
				this.isProcess = false;
				if (res.cliente == null || res.cliente.id == 0) {
					this.onErrorRegister();
				} else {
					this.isFinish = true;
				}
			},
			error: () => {
				this.onErrorRegister();
				this.isProcess = false;
			}
		});
	}

	onErrorRegister() {
		this.showAlert('Al parecer hubo un error en su registro. Por favor, inténtalo más tarde.');
		const dataCelular = this.fullCelular.split('.');
		this.phoneField.setValue(dataCelular[1]);
		this.dialField.setValue(dataCelular[0]);
		this.dateBirthField.setValue(this.fullDate);
		//this.childBirthdate.setValue(this.fullDate);
	}

	checkInteres(index: number) {
		const interes = this.listIntereses[index - 1];
		this.listIntereses[index - 1] = { ...interes, selected: !interes.selected };
	}

	changeSelectGender($event: number) {
		this.genderField.setValue($event);
	}

	changeBirthdate($event: any) {
		console.log('$event', $event);
		console.log('this.birthdate', this.birthdate);
		this.dateBirthField.setValue($event.date);
		this.birthdate = $event.date ? formatBirthdate($event.date) : '';
	}

	changeDocument($event: any) {
		this.isValidDocument = $event.isValid;
		this.typeDocumentField.setValue($event.indexType);
		this.numberDocumentField.setValue($event.document);
	}

	changePhone($event: any) {
		this.isValidPhone = $event.isValid;
		this.phoneField.setValue($event.phone);
		this.dialField.setValue($event.dial);
	}

	getListInterest() {
		this._authService.getListInterest().subscribe({
			next: (res) => {
				this.listIntereses = res;
			}
		});
	}

	get passwordField(): AbstractControl {
		return this.formGroup.get('Contrasenia')!;
	}

	get emailField(): AbstractControl {
		return this.formGroup.get('CorreoElectronico')!;
	}

	get sesionFacebookField(): AbstractControl {
		return this.formGroupDatos.get('SesionFacebook')!;
	}

	get idFacebookField(): AbstractControl {
		return this.formGroupDatos.get('IdFacebook')!;
	}

	get tokenFacebookField(): AbstractControl {
		return this.formGroupDatos.get('FacebookToken')!;
	}

	get repeatPasswordField(): AbstractControl {
		return this.formGroup.get('repeatpassword')!;
	}

	get acceptTermsField(): AbstractControl {
		return this.formGroup.get('TerminosCondiciones')!;
	}

	get politicasRedesField(): AbstractControl {
		return this.formGroup.get('PoliticasRedes')!;
	}

	get nameField(): AbstractControl {
		return this.formGroupDatos.get('Nombre')!;
	}

	get lastNameField(): AbstractControl {
		return this.formGroupDatos.get('Apellido')!;
	}

	get dateBirthField(): AbstractControl {
		return this.formGroupDatos.get('fechaNacimiento')!;
	}

	get genderField(): AbstractControl {
		return this.formGroupDatos.get('genero')!;
	}

	get typeDocumentField(): AbstractControl {
		return this.formGroupDatos.get('TipoDocumentoId')!;
	}

	get phoneField(): AbstractControl {
		return this.formGroupDatos.get('NumeroCelular')!;
	}

	get dialField(): AbstractControl {
		return this.formGroupDatos.get('PaisCelular')!;
	}

	get numberDocumentField(): AbstractControl {
		return this.formGroupDatos.get('Identificacion')!;
	}

	get imageField(): AbstractControl {
		return this.formGroupDatos.get('imagenPerfil')!;
	}

	get interestField(): AbstractControl {
		return this.formGroupDatos.get('intereses')!;
	}

	get emailRecoverField(): AbstractControl {
		return this.formGroupRecover.get('emailRecover')!;
	}

	showLoginSection() {
		this.formGroup.reset();
		this.formGroupDatos.reset();
		this.fullCelular = '';
		this._authService.cleanAuthTemporal();
		this.clickButton.emit(true);
	}

	closeModal() {
		this.clickClose.emit();
	}

	sendRecoverPassword() {
		if (this.formGroupRecover.valid) {
			this.isProcess = true;
			this._authService.recoverPassword(this.emailRecoverField.value).subscribe({
				next: (res) => {
					if (res.mensaje === 'El correo no existe.') {
						this.isEmailNotRegister = true;
					} else {
						this.isEmailDelivered = true;
						this.isEmailNotRegister = false;
					}
					this.isProcess = false;
				},
				error: () => {
					this.showAlert('Al parecer hubo un error al recuperar su contraseña. Por favor, inténtalo más tarde.', true);
					this.isProcess = false;
				}
			});
		} else {
			this.formGroupRecover.markAllAsTouched();
		}
	}

	showSuccess() {
		this.closeModal();
		const modalRef = this._modalService.open(ModalMessageComponent, {
			scrollable: true,
			centered: true,
			backdrop: 'static',
			windowClass: 'modal-confirmation'
		});
		modalRef.componentInstance.title = 'Se envió un correo de recuperacion de su cuenta, por favor verifique';
		modalRef.componentInstance.clickButton.subscribe(() => {
			this.closeModal();
		});
	}
	/****************************Auth Facebook****************************** */
	registerFacebook() {
		const valueCheck = this.politicasRedesField.value;
		this.formGroup.reset();
		this.politicasRedesField.setValue(valueCheck);
		this.politicasRedesField.addValidators([Validators.requiredTrue]);
		if (!this.politicasRedesField.value) {
			this.isCheckFacebook = true;
		} else {
			this.isCheckFacebook = false;
			this.signInWithFB();
		}
	}

	signInWithFB() {
		this.fb
			.login(this.options)
			.then((response: LoginResponse) => {
				this.generateToken(response);
			})
			.catch(() => {
				this._authService.deleteCookieFB();
			});
	}

	generateToken(response: LoginResponse) {
		const url = `https://graph.facebook.com/oauth/access_token?grant_type=fb_exchange_token&client_id=${environment.clientIdFacebook}&client_secret=${environment.clientSecretFacebook}&fb_exchange_token=${response.authResponse.accessToken}`;
		this.fb.api(url).then((res) => {
			this.getInfoEmail(res.access_token);
		});
	}

	getInfoEmail(token: string) {
		const url = `https://graph.facebook.com/me?fields=first_name,last_name,email,id&access_token=${token}`;
		this.fb
			.api(url)
			.then((res) => {
				this.registerFacebookWithToken(res, token);
			})
			.catch(() => {
				this.showAlert('Hubo un error con el Servicio de Facebook. Por favor, inténtalo más tarde');
			});
	}

	registerFacebookWithToken(res: any, token: string) {
		this.isProcessFacebook = true;
		const numberRandom = Math.ceil(Math.random() * 4);
		this.imageField.setValue(this.colors[numberRandom - 1]);
		this.sesionFacebookField.setValue(true);
		this.idFacebookField.setValue(res.id);
		this.tokenFacebookField.setValue(token);
		this.nameField.setValue(res.first_name);
		this.lastNameField.setValue(res.last_name);

		const allData = { ...this.formGroupDatos.value, ...this.formGroup.value };
		this._authService.registerWithFacebook(allData, res.email).subscribe({
			next: (res) => {
				this.isProcessFacebook = false;
				if (res.cliente == null) {
					this.showAlert('El correo ingresado ya esta registrado. Por favor inicie sesion');
				} else if (res.cliente.id == 0)
					this.showAlert('Al parecer hubo un error en su registro. Por favor, inténtalo más tarde.');
				else {
					this._localService.setNewLocal('lfb', 'true');
					this.isFinish = true;
				}
			},
			error: () => {
				this.isProcessFacebook = false;
				this.showAlert('Al parecer hubo un error en su registro. Por favor, inténtalo más tarde.');
			}
		});
	}

	changeCheckFacebook() {
		const valueCheck = this.politicasRedesField.value;
		if (!valueCheck) {
			this.isCheckFacebook = false;
		}
	}

	/****************************End Auth Facebook****************************** */

	goToLogin() {
		this.clickHideForget.emit(false);
	}

	goToHome() {
		this.clickClose.emit();
		this._router.navigateByUrl('/');
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.getScreenWidth = window.innerWidth;
	}

	public executeRecaptchaV3(isOmit: boolean) {
		this.recaptchaV3Service.execute('myAction').subscribe({
			next: () => {
				this.registerStepTree(isOmit);
			},
			error: () => {
				this.onError();
			}
		});
	}

	onError() {
		this.showAlert('Parece que hubo un error en la verificación en el Recaptcha. Por favor, inténtalo más tarde.');
	}

	onScroll(event: any) {
		if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
			this.isEndScroll = true;
		} else {
			this.isEndScroll = false;
		}
	}
}

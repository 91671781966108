import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationAlertComponent } from './notification-alert.component';
import { ModalNotificationDetailComponent } from './modal-notification-detail/modal-notification-detail.component';
import { LoaderModule } from '../loader/loader.module';
import { ButtonModule } from '../button/button.module';

@NgModule({
	imports: [CommonModule, LoaderModule, ButtonModule],
	exports: [NotificationAlertComponent],
	declarations: [NotificationAlertComponent, ModalNotificationDetailComponent],
	providers: []
})
export class NotificationAlertModule {}
